import React, { HTMLAttributes, useMemo, useEffect, Fragment } from 'react';

import { Title, Button, Text, TitleProps } from '@soluto-design/react';
import cls from '@soluto-design/styles/cls';

import {
    renderNodeRule,
    StructuredText,
    StructuredTextGraphQlResponse,
} from 'react-datocms';
import {
    isParagraph,
    isLink,
    isRoot,
    validate,
} from 'datocms-structured-text-utils';
import Link from '@soluto-design/link';

import styles from './styles.module.scss';

export interface FeaturedTextProps extends HTMLAttributes<HTMLDivElement> {
    text: StructuredTextGraphQlResponse | string | undefined;
    size?: 'medium' | 'large' | 'small';
}

export default function FeaturedText({
    text,
    className,
    size,
}: FeaturedTextProps) {
    useEffect(() => {
        if (typeof window !== 'undefined' && (window as any).cookiehub) {
            (window as any).cookiehub.renderDeclaration();
        }
    }, []);

    const fontStyle = useMemo(() => {
        switch (size) {
            case 'large':
                return 'font-b300-regular';
            case 'medium':
                return 'font-b400-regular';
            case 'small':
                return 'font-b500-regular';
            default:
                return null;
        }
    }, [size]);

    const isLarge = useMemo(() => size === 'large', [size]);

    if (typeof text === 'string') {
        return (
            <Text
                className={cls(
                    styles.shared,
                    styles.html,
                    fontStyle,
                    className,
                )}
                component="div"
                isHtml>
                {text}
            </Text>
        );
    }

    if (typeof text === 'object') {
        const documentValidate = text && validate(text?.value);

        if (!documentValidate?.valid) {
            return null;
        }

        return (
            <div
                className={cls(
                    styles.shared,
                    isLarge ? styles.structured : styles.html,
                    fontStyle,
                    className,
                )}>
                <StructuredText
                    data={text}
                    customNodeRules={[
                        renderNodeRule(
                            isParagraph,
                            ({
                                adapter: { renderNode },
                                children,
                                key,
                                ancestors,
                            }): any => {
                                if (!children) {
                                    return null;
                                }

                                if (isRoot(ancestors[0])) {
                                    if (
                                        children[0].type === 'div' &&
                                        children[0].props
                                            ?.dangerouslySetInnerHTML
                                    ) {
                                        return (
                                            <Fragment key={key}>
                                                {children}
                                            </Fragment>
                                        );
                                    } else if (isLarge) {
                                        return renderNode(
                                            'div',
                                            {
                                                key,
                                                'data-inline-block': 'true',
                                            },
                                            children,
                                        );
                                    } else {
                                        return renderNode(
                                            'p',
                                            {
                                                key,
                                            },
                                            children,
                                        );
                                    }
                                } else {
                                    return children;
                                }
                            },
                        ),
                        renderNodeRule(
                            isLink,
                            ({ node, children, key }): any => {
                                return isLarge ? (
                                    <Button
                                        key={key}
                                        href={node.url}
                                        className={styles['inline-button']}
                                        variant="secondary"
                                        size="text">
                                        {node.children?.length === 1
                                            ? node.children[0].value
                                            : children}
                                    </Button>
                                ) : (
                                    <Link key={key} href={node.url}>
                                        {node.children?.length === 1
                                            ? node.children[0].value
                                            : children}
                                    </Link>
                                );
                            },
                        ),
                    ]}
                    renderInlineRecord={({ record }) => {
                        switch (record.__typename) {
                            case 'DatoCmsInlineHeading':
                                return (
                                    <Title
                                        component={
                                            record?.heading as TitleProps['component']
                                        }>
                                        {record?.content as string}
                                    </Title>
                                );
                            case 'DatoCmsCode':
                                return (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: `${record.code}`,
                                        }}
                                    />
                                );
                            default:
                                return null;
                        }
                    }}
                    renderLinkToRecord={({ record }) => {
                        switch (record.__typename) {
                            case 'DatoCmsInlineHeading':
                                return (
                                    <Title
                                        component={
                                            record?.heading as TitleProps['component']
                                        }>
                                        {record?.content as string}
                                    </Title>
                                );
                            case 'DatoCmsCode':
                                return (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: `${record.code}`,
                                        }}
                                    />
                                );
                            default:
                                return null;
                        }
                    }}
                    renderText={(text: string, key: React.Key) => {
                        return text.trim() && isLarge ? (
                            <p key={key}>{text}</p>
                        ) : (
                            text
                        );
                    }}
                />
            </div>
        );
    }

    return null;
}
