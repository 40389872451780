import React from 'react';

import { Row, Col, Container } from '@soluto-design/react';
import cls from '@soluto-design/styles/cls';

import FeaturedText, { FeaturedTextProps } from '../../molecules/FeaturedText';
import SectionTheme, { SectionThemeProps } from '../../molecules/SectionTheme';

export interface TextSectionProps extends FeaturedTextProps {
    sectionClassName?: string;
    previousSectionType?: string;
    theme?: SectionThemeProps['theme'];
}

export default function TextSection({
    sectionClassName,
    previousSectionType,
    size = 'large',
    theme,
    ...props
}: TextSectionProps) {
    return (
        <SectionTheme
            className={cls('py-huge', sectionClassName)}
            theme={theme}>
            <Container>
                <Row>
                    <Col
                        col={
                            size === 'large'
                                ? { md: 7 }
                                : size === 'medium'
                                ? { md: 7, lg: 9, hd: 8 }
                                : size === 'small'
                                ? { md: 6, lg: 7 }
                                : {}
                        }>
                        <FeaturedText {...props} size={size} />
                    </Col>
                </Row>
            </Container>
        </SectionTheme>
    );
}
