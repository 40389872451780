import React from 'react';
import { Title, Text, Icon } from '@soluto-design/react';
import cls from '@soluto-design/styles/cls';
import Link from '@soluto-design/link';
import Picture, { PictureProps } from '@soluto-design/picture';
import styles from './styles.module.scss';

export interface GridSmallAtomProps {
    image?: PictureProps;
    name: string;
    description: string;
    link?: string;
    className?: string;
}

export default function GridSmallAtom({
    image = '',
    name,
    description,
    link,
    className = '',
}: GridSmallAtomProps) {
    return (
        <div className={cls(className)}>
            <Link href={link} target="_blank">
                <div className={cls(styles['image-wrapper'])}>
                    {image?.gatsbyImageData ? (
                        <Picture src={image} alt={name} layout="fill" />
                    ) : (
                        <Icon
                            icon="plus"
                            size={32}
                            className={styles.iconAux}
                        />
                    )}
                </div>
            </Link>
            <div className={cls('font-b500-regular text-uppercase mt-md')}>
                {link ? (
                    <Link
                        title={name}
                        href={link}
                        target="_blank"
                        className={cls(
                            'no-decoration display-xs-inline-flex align-xs-center mb-tiny',
                        )}>
                        <Title component="h4" className={cls('mr-xxs')}>
                            {name}
                        </Title>
                        <Icon icon="arrow-corner" type="right-up" />
                    </Link>
                ) : (
                    <Title component="h4">{name}</Title>
                )}
            </div>
            <Text
                component="p"
                isHtml
                className={cls('font-b500-regular pr-md')}>
                {description}
            </Text>
        </div>
    );
}
