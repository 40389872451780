import React, { HTMLAttributes } from 'react';

import cls from '@soluto-design/styles/cls';

export interface SectionThemeProps extends HTMLAttributes<HTMLElement> {
    theme?: 'black' | 'white' | 'lightgrey';
    changeThemeTo?: 'black' | 'white' | 'lightgrey';
}

export default function SectionTheme({
    changeThemeTo,
    theme,
    id,
    className,
    children,
}: SectionThemeProps) {
    return (
        <section
            {...(!!className && { className: cls(className) })}
            {...(!!id && { id })}
            {...(!!changeThemeTo && { 'data-theme-change': changeThemeTo })}
            {...(!!theme && { 'data-theme': theme })}>
            {children}
        </section>
    );
}
