import React, { HTMLAttributes, CSSProperties } from 'react';

import { Collapse } from 'react-collapse';

import cls from '@soluto-design/styles/cls';
import { Button, Icon } from '@soluto-design/react';
import type { Color } from '.';

import styles from './styles.module.scss';

export const BLACKBOARD_SHOW_TOOLTIP_KEY = '@soluble/blackboard/moblie-tooltip';

export interface ToolsTranslations {
    button: string;
    color: string;
    thickness: string;
    labelDraw: string;
    labelTools: string;
    labelUndo: string;
}

export interface ToolsProps extends HTMLAttributes<HTMLDivElement> {
    isDrawing: boolean;
    toggleDrawing: any;
    isChoosing: boolean;
    toggleChoosing: any;
    isSharing: boolean;
    toggleSharing: any;
    undo: any;
    setColor: any;
    currentColor?: Color;
    setThickness: any;
    currentThickness?: number;
    colors: Color[];
    thickness: number[];
    translations: ToolsTranslations;
}

export default function Tools({
    isDrawing,
    toggleDrawing,
    isChoosing,
    toggleChoosing,
    isSharing,
    toggleSharing,
    undo,
    setColor,
    currentColor,
    setThickness,
    currentThickness,
    colors,
    thickness,
    translations,
}: ToolsProps) {
    return (
        <>
            <button
                className={cls(styles.overlay, isSharing && styles.show)}
                onClick={toggleSharing}
            />
            <Collapse
                theme={{
                    collapse: cls(styles.collapse, 'radius-medium'),
                    content: cls(
                        styles['collapse-body'],
                        'radius-medium',
                        isChoosing && 'elevation-small',
                    ),
                }}
                isOpened={isChoosing}>
                <ul
                    className={cls(
                        'display-xs-flex align-xs-center justify-xs-between',
                    )}>
                    {colors?.map((color, i) => (
                        <li key={`colors-${i}`}>
                            <button
                                tabIndex={-1}
                                disabled={currentColor?.hex === color.hex}
                                onClick={() => {
                                    setColor(color);
                                }}
                                className={cls(
                                    styles.circle,
                                    currentColor?.hex === color.hex &&
                                        styles['active'],
                                )}
                                title={`${translations.color || 'Color'} ${
                                    color.name
                                }`}
                                style={
                                    {
                                        '--size': `${currentThickness}px`,
                                        '--color': color.hex,
                                    } as CSSProperties
                                }></button>
                        </li>
                    ))}
                </ul>
                <hr />
                <ul
                    className={cls(
                        'display-xs-flex align-xs-center justify-xs-between',
                    )}>
                    {thickness?.map((thick, i) => (
                        <li key={`thick-${i}`}>
                            <button
                                disabled={currentThickness === thick}
                                tabIndex={-1}
                                className={cls(
                                    styles['circle'],
                                    currentThickness === thick &&
                                        styles['active'],
                                )}
                                onClick={() => {
                                    setThickness(thick);
                                }}
                                title={`${
                                    translations.thickness || 'Thickness'
                                } ${thick}px`}
                                style={
                                    {
                                        '--size': `${thick}px`,
                                        '--color': currentColor?.hex,
                                    } as CSSProperties
                                }
                            />
                        </li>
                    ))}
                </ul>
            </Collapse>
            <div
                className={cls(
                    styles['tools-actions'],
                    'display-xs-flex align-xs-center justify-xs-between radius-round',
                    (isDrawing || isSharing) && styles.show,
                )}>
                <div
                    className={cls(
                        styles['tools-actions-left'],
                        'display-xs-flex align-xs-center justify-xs-start',
                    )}>
                    <button
                        disabled={isSharing}
                        onClick={undo}
                        aria-label={translations.labelUndo || 'Undo'}
                        tabIndex={-1}
                        className={cls('text-primary', styles.button)}>
                        <Icon size={28} icon="undo" />
                    </button>
                    <button
                        aria-label={translations.labelTools || 'Tools'}
                        disabled={isSharing}
                        tabIndex={-1}
                        className={cls(
                            styles.button,
                            styles.circle,
                            isChoosing && styles.active,
                        )}
                        onClick={toggleChoosing}
                        style={
                            {
                                '--size': `${currentThickness || 0}px`,
                                '--color': currentColor?.hex,
                            } as CSSProperties
                        }></button>
                </div>
                <Button
                    tabIndex={-1}
                    className={cls(isSharing && styles.buttonActive)}
                    aria-label={translations.button || 'Send'}
                    onClick={toggleSharing}
                    variant="secondary"
                    size="text"
                    component="button">
                    {translations.button || 'Send'}
                </Button>
            </div>
            <button
                aria-label={translations.labelDraw || 'Draw'}
                tabIndex={-1}
                data-theme="white"
                className={cls(
                    styles['tools-toggler'],
                    'text-primary background-primary radius-round',
                    (isDrawing || isSharing) && 'elevation-small',
                )}
                onClick={toggleDrawing}>
                <Icon
                    size={24}
                    icon={isDrawing || isSharing ? 'close' : 'pencil-1'}
                />
            </button>
        </>
    );
}
