import React, {
    HTMLAttributes,
    useState,
    Fragment,
    useRef,
    useMemo,
    useEffect,
} from 'react';
import { Collapse } from 'react-collapse';
import { LinkProps } from '@soluto-design/link';
import { useIsBreakpointUp, Container, Row, Col } from '@soluto-design/react';
import cls from '@soluto-design/styles/cls';
import LinkNavigation from '../../molecules/LinkNavigation';
import LinkInPage from '../../molecules/LinkInPage';
import styles from './styles.module.scss';

function orderArrayByProperty(array, property) {
    return array.sort((a, b) => {
        if (a[property] < b[property]) {
            return -1;
        }
        if (a[property] > b[property]) {
            return 1;
        }
        return 0;
    });
}

function divideArrayInParts(array, parts) {
    const result = [];
    const size = Math.ceil(array.length / parts);

    for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size));
    }

    return result;
}

function getHash() {
    if (typeof window === 'undefined') {
        return '';
    }

    return window.location.hash.replace('#', '');
}

const IS_TOUCH =
    typeof window !== 'undefined' &&
    ('ontouchstart' in window ||
        window.navigator.maxTouchPoints > 0 ||
        window.navigator.msMaxTouchPoints > 0);

interface LinkGroup {
    title: string;
    slug: string;
    links: LinkProps[];
}

export interface BlockLinksSectionProps extends HTMLAttributes<HTMLDivElement> {
    links: LinkGroup[];
}

export default function BlockLinksSection({
    links,
    ...props
}: BlockLinksSectionProps) {
    const [openItem, setOpenItem] = useState<string | boolean>(false);
    const isDesktop = useIsBreakpointUp('md');
    const isDesktopLarge = useIsBreakpointUp('xl');
    const scrollRef = useRef<HTMLDivElement>();

    const slugs = useMemo(() => links.map((link) => link.slug), [links]);

    useEffect(() => {
        if (typeof window === 'undefined' || !scrollRef.current) {
            return;
        }

        setTimeout(() => {
            const hash = getHash();
            if (hash && slugs.includes(hash)) {
                scrollRef.current?.scrollIntoView();
                setOpenItem(hash);
            }
        }, 100);

        const onHashChange = () => {
            const hash = getHash();
            setOpenItem(hash && slugs.includes(hash) ? hash : false);
        };

        window.addEventListener('hashchange', onHashChange);
        return () => {
            window.removeEventListener('hashchange', onHashChange);
        };
    }, [setOpenItem, scrollRef, slugs]);

    const desktopLinks: LinkProps[][] = useMemo(() => {
        const allLinks = {};

        links.forEach(({ links, slug }) => {
            links.forEach((link) => {
                allLinks[link.children] = {
                    ...link,
                    slug,
                };
            });
        });

        const finalLinks = orderArrayByProperty(
            Object.values(allLinks),
            'children',
        );

        return divideArrayInParts(finalLinks, isDesktopLarge ? 3 : 2);
    }, [links, isDesktopLarge]);

    return (
        <section {...props}>
            <div ref={scrollRef} className={styles.scrollRef} />
            <Container>
                <Row>
                    <Col col={{ md: 2, lg: 4, xl: 3 }}>
                        <div
                            {...(isDesktop && {
                                onMouseLeave: () => {
                                    window.location.hash = '#_';
                                    setOpenItem(false);
                                },
                            })}
                            className={styles.titles}>
                            {links.map(({ links, title, slug }, i) => (
                                <Fragment key={`link-group-${i}`}>
                                    <LinkInPage
                                        href={`#${slug}`}
                                        {...(slug === openItem && {
                                            onClick: (e) => {
                                                e.preventDefault();
                                                window.location.hash = '#_';
                                                setOpenItem(false);
                                            },
                                        })}
                                        {...(isDesktop &&
                                            !IS_TOUCH && {
                                                onMouseEnter: () => {
                                                    window.location.hash = `#${slug}`;
                                                },
                                            })}
                                        className={cls(
                                            'mb-xxs',
                                            styles.title,
                                            openItem === slug && styles.open,
                                        )}
                                        iconName={
                                            isDesktop
                                                ? openItem === slug
                                                    ? undefined
                                                    : 'none'
                                                : `arrow-chevron--type-${
                                                      openItem === slug
                                                          ? 'up'
                                                          : 'down'
                                                  }`
                                        }>
                                        {title}
                                    </LinkInPage>
                                    <Collapse
                                        theme={{
                                            collapse: cls(
                                                styles.collapse,
                                                'display-md-none',
                                            ),
                                            content: cls('pb-xl'),
                                        }}
                                        isOpened={openItem === slug}>
                                        {links.map((link, ii) => (
                                            <LinkNavigation
                                                key={`link-group-${i}-${ii}`}
                                                {...link}
                                                size="small"
                                            />
                                        ))}
                                    </Collapse>
                                </Fragment>
                            ))}
                        </div>
                    </Col>
                    {desktopLinks.map((links, i) => (
                        <Col
                            key={`desktop-links-${i}`}
                            col={{ md: 3, lg: 4, xl: 3 }}
                            className={styles.desktopCol}>
                            {links.map(({ slug, ...link }, ii) => (
                                <LinkNavigation
                                    key={`desktop-link-${i}-${ii}`}
                                    {...link}
                                    className={cls(
                                        styles.link,
                                        !!openItem &&
                                            openItem !== slug &&
                                            styles.disabled,
                                    )}
                                    size="small"
                                />
                            ))}
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
}
