import React from 'react';

import { Row, Col, Container } from '@soluto-design/react';

import Form, { FormProps } from '../../molecules/Form';
import FeaturedText from '../../molecules/FeaturedText';
import SectionTheme, { SectionThemeProps } from '../../molecules/SectionTheme';
import cls from '@soluto-design/styles/cls';

import styles from './styles.module.scss';

export interface FormSectionProps extends SectionThemeProps {
    text?: string;
    form?: FormProps;
    id?: string;
}

export default function FormSection({
    text,
    form,
    id,
    ...props
}: FormSectionProps) {
    return (
        <SectionTheme {...props} className={cls('py-huge', styles.section)}>
            <Container>
                <Row>
                    {!!text && (
                        <Col col={{ md: 7 }} className="mb-xxxl">
                            <FeaturedText size="large" text={text} />
                        </Col>
                    )}
                    <Col col={{ md: 6, lg: 8 }}>
                        {!!form && <Form id={id} {...form} />}
                    </Col>
                </Row>
            </Container>
        </SectionTheme>
    );
}
