import React from 'react';

import {
    SelectionControlCheckbox,
    SelectionControlCheckboxProps,
} from '@soluto-design/react';

import cls from '@soluto-design/styles/cls';

import styles from './styles.module.scss';

export interface CheckboxProps extends SelectionControlCheckboxProps {}

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
    ({ className, ...otherProps }, ref) => {
        return (
            <SelectionControlCheckbox
                ref={ref}
                className={cls(styles.root, className)}
                {...otherProps}
            />
        );
    },
);

export default Checkbox;
