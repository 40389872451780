import React, {
    forwardRef,
    useImperativeHandle,
    Children,
    useMemo,
} from 'react';
import cls from '@soluto-design/styles/cls';
import variables from '@soluto-design/styles/variables';
import layoutStyles from '@soluto-design/styles/components/layout';
import { useKeenSlider, KeenSliderPlugin } from 'keen-slider/react';
import styles from './styles.module.scss';
import 'keen-slider/keen-slider.min.css';

export interface SliderProps extends HTMLAttributes<HTMLDivElement> {
    variant?: 'featured-posts' | 'related-posts' | 'default-posts' | 'quote';
    className?: string;
    children: React.ReactNode;
}

const WheelControls: KeenSliderPlugin = (slider) => {
    let touchTimeout: ReturnType<typeof setTimeout>;
    let position: {
        x: number;
        y: number;
    };
    let wheelActive: boolean;

    function dispatch(e: WheelEvent, name: string) {
        position.x -= e.deltaX;
        position.y -= e.deltaY;
        slider.container.dispatchEvent(
            new CustomEvent(name, {
                detail: {
                    x: position.x,
                    y: position.y,
                },
            }),
        );
    }

    function wheelStart(e: WheelEvent) {
        position = {
            x: e.pageX,
            y: e.pageY,
        };
        dispatch(e, 'ksDragStart');
    }

    function wheel(e: WheelEvent) {
        dispatch(e, 'ksDrag');
    }

    function wheelEnd(e: WheelEvent) {
        dispatch(e, 'ksDragEnd');
    }

    function eventWheel(e: WheelEvent) {
        // e.preventDefault();
        if (!wheelActive) {
            wheelStart(e);
            wheelActive = true;
        }
        wheel(e);
        clearTimeout(touchTimeout);
        touchTimeout = setTimeout(() => {
            wheelActive = false;
            wheelEnd(e);
        }, 50);
    }

    slider.on('created', () => {
        slider.container.addEventListener('wheel', eventWheel, {
            //  passive: false,
        });
    });
};

const Slider = forwardRef(
    ({ children, className, variant, ...props }: SliderProps, ref) => {
        const [isFeatured, isQuote, isFeaturedOrQuote] = useMemo(() => {
            return [
                variant === 'featured-posts',
                variant === 'quote',
                variant === 'featured-posts' || variant === 'quote',
            ];
        }, [variant]);

        const [sliderRef, slider] = useKeenSlider<HTMLDivElement>(
            {
                mode: isFeaturedOrQuote ? 'free-snap' : 'free',
                slides: {
                    ...(!isQuote && { origin: isFeatured ? 'center' : 0.5 }),
                    perView: 'auto',
                    spacing: isQuote ? 12 : 8,
                },
                initial: 0,
                ...(!isQuote && { loop: true }),
                breakpoints: {
                    [`(min-width: ${variables['layout-breakpoint-lg']}px)`]: {
                        ...(isQuote && { drag: false }),
                        ...(!isQuote && {
                            origin: isFeatured ? 'center' : 0.5,
                            perView: 'auto',
                            spacing: 12,
                        }),
                    },
                    [`(min-width: ${variables['layout-breakpoint-xl']}px)`]: {
                        ...(isQuote && { drag: false }),
                        slides: {
                            ...(!isQuote && {
                                origin: isFeatured ? 'center' : 0.5,
                            }),
                            perView: 'auto',
                            spacing: 16,
                        },
                    },
                },
            },
            [WheelControls],
        );

        useImperativeHandle(ref, () => ({
            prev: () => {
                if (slider) {
                    slider.current.prev();
                }
            },
            next: () => {
                if (slider) {
                    slider.current.next();
                }
            },
        }));

        return (
            <div
                ref={sliderRef}
                {...props}
                className={cls(
                    variant === 'quote' && layoutStyles.container,
                    styles.wrapper,
                    'keen-slider',
                    className,
                )}>
                {variant === 'quote' && (
                    <div className={cls('keen-slider__slide', styles.border)} />
                )}
                {Children.map(children, (child, i) => (
                    <div
                        key={`slide-${i}`}
                        className={cls(
                            'keen-slider__slide',
                            variant === 'featured-posts'
                                ? styles.featuredSlide
                                : styles.slide,
                        )}>
                        {child}
                    </div>
                ))}
                {variant === 'quote' && (
                    <div className={cls('keen-slider__slide', styles.border)} />
                )}
                {variant === 'featured-posts' && (
                    <>
                        <button
                            className={cls(
                                'display-xs-none display-lg-block position-xs-absolute',
                                styles.control,
                            )}
                            onClick={() => slider.current.prev()}
                        />
                        <button
                            className={cls(
                                'display-xs-none display-lg-block position-xs-absolute',
                                styles.control,
                                styles.next,
                            )}
                            onClick={() => slider.current.next()}
                        />
                    </>
                )}
            </div>
        );
    },
);

export default Slider;
