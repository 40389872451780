import React from 'react';
import { Container, Row, Col } from '@soluto-design/react';
import BlockService, { BlockServiceProps } from '../../molecules/BlockService';
import cls from '@soluto-design/styles/cls';
import styles from './styles.module.scss';

export interface ServicesSectionProps {
    services: BlockServiceProps[];
}

export default function ServicesSection({
    services,
    ...props
}: ServicesSectionProps) {
    return (
        <section {...props}>
            {!!services.length && (
                <Container>
                    <Row className={cls(styles.servicesContainer)}>
                        {services.map((service, index) => (
                            <Col
                                col={{ md: 6, lg: 6 }}
                                offset={{ md: 1, lg: 3 }}
                                className={cls(
                                    'display-xs-flex flex-xs-column pt-lg background-primary',
                                )}>
                                <BlockService key={index} {...service} />
                            </Col>
                        ))}
                    </Row>
                </Container>
            )}
        </section>
    );
}
