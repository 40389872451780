module.exports = [{
      plugin: require('../../../node_modules/@solublestudio/gatsby-theme-soluble-source/gatsby-browser.js'),
      options: {"plugins":[],"primaryLanguage":"es","language":{"es":"es","en":"en"},"createLocalizedNotFound":false,"pathsByLanguage":{"case-study":{"es":"que","en":"what"},"mailchimp":{"es":"solublabla","en":"solublabla"},"articulos":{"es":"solublabla","en":"solublabla"},"blabla-tag":{"es":"solublabla/categoria","en":"solublabla/category"},"job-offer":{"es":"oferta","en":"offer"}},"sitemap":{"sitemapByTemplate":{"search":{"changefreq":"weekly","priority":0.8},"blabla-tag":{"changefreq":"weekly","priority":0.8},"blabla-page":{"changefreq":"monthly","priority":0.4},"job-offer":{"changefreq":"weekly","priority":0.6},"case-study":{"changefreq":"monthly","priority":0.6},"articulos":{"changefreq":"monthly","priority":0.6},"mailchimp":{"changefreq":"monthly","priority":0.4}}},"cloudinary":{"cloudName":"daedai8zs","apiKey":"838979837426985","apiSecret":"e59nswYgzeUK_xXKAFsleYxlFKE","uploadFolder":"gtsb","overwriteExisting":false,"cdnHost":"https://media.solublestudio.com"},"notion":{"databasesIds":["ce4114c5a8004ad2b8f9aafdf29e15b9","94ae0a47ee4747eeb751cd51b417fb8b","4bd61bb59ef14ac69c312e4111828d2c","0834e4730da74729ba55f47361bb0d6f"],"previewMode":false},"datocms":{"previewMode":false,"modelPages":["Page"],"cdnHost":"https://media.solublestudio.com"},"slices":{"header":"/vercel/path0/apps/website/src/components/Header/index.tsx","footer":"/vercel/path0/apps/website/src/components/Footer/index.tsx"}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Soluble Studio","short_name":"Soluble","start_url":"/","background_color":"#FFFFFF","theme_color":"#0E0E0E","display":"minimal-ui","icon":"src/img/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"222c73d40ffc224620daa61daa9d60e6"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NQ7F927","gtmAuth":"kZYLFhR-RGP_A86iyMqMdw","gtmPreview":"env-1","defaultDataLayer":{"type":"function","value":"function () {\n                    return {\n                        lang: window.document.documentElement.lang,\n                    };\n                }"},"enableWebVitalsTracking":true,"includeInDevelopment":true,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.jsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
