import React from 'react';
import { Container } from '@soluto-design/react';
import CaseStudy, { CaseStudyProps } from '../../molecules/CaseStudy';
import cls from '@soluto-design/styles/cls';

import SectionTheme, { SectionThemeProps } from '../../molecules/SectionTheme';

export interface CasesStudiesSectionProps extends SectionThemeProps {
    cases: CaseStudyProps[];
}

export default function CasesStudiesSection({
    cases = [],
    ...props
}: CasesStudiesSectionProps) {
    return (
        <SectionTheme {...props} className={cls('py-huge')}>
            <Container>
                {cases.map((item, i) => {
                    return <CaseStudy {...item} key={i} />;
                })}
            </Container>
        </SectionTheme>
    );
}
