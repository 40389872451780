import React, {
    useMemo,
    HTMLAttributes,
    Fragment,
    ReactNode,
    CSSProperties,
} from 'react';

import { Icon } from '@soluto-design/react';
import cls from '@soluto-design/styles/cls';
import Tag from '../../atoms/Tag';

import styles from './styles.module.scss';

export interface BandTagProps extends HTMLAttributes<HTMLElement> {
    separator?: string;
    tags: string[];
}

export default function BandTag({
    separator,
    tags,
    className,
    ...props
}: BandTagProps) {
    const items = useMemo(() => {
        let finalTags: Array<ReactNode> = [...tags];

        if (separator) {
            const middleIndex = Math.ceil(tags.length / 2);
            const firstHalf = finalTags.splice(0, middleIndex);
            const secondHalf = finalTags.splice(-middleIndex);

            const finalSeparator = separator.startsWith('icon:') ? (
                <Icon
                    className={styles.separator}
                    icon={separator.replace('icon:', '')}
                    size={32}
                />
            ) : (
                <img className={styles.separator} src={separator} alt="" />
            );

            finalTags = firstHalf
                .concat(finalSeparator as ReactNode)
                .concat(secondHalf);
        }

        return Array.from({ length: tags.length < 5 ? 4 : 3 }).fill(
            finalTags,
        ) as ReactNode[][];
    }, [tags, separator]);

    return (
        <div
            {...props}
            className={cls(
                styles.root,
                'display-xs-flex align-xs-center',
                className,
            )}>
            {items.map((item, i) => (
                <div
                    key={`band-item-${i}`}
                    style={
                        {
                            '--anim-time': `${tags.length * 8750}ms`,
                        } as CSSProperties
                    }
                    data-animation
                    className={cls(
                        styles.item,
                        'display-xs-flex align-xs-center',
                    )}>
                    {item.map((subitem, j) => (
                        <Fragment key={`band-item-${i}-${j}`}>
                            {typeof subitem === 'string' ? (
                                <Tag variant="outline" size="large">
                                    {subitem}
                                </Tag>
                            ) : (
                                subitem
                            )}
                        </Fragment>
                    ))}
                </div>
            ))}
        </div>
    );
}
