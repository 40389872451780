import React, { useState } from 'react';
import { Icon } from '@soluto-design/react';
import { Collapse } from 'react-collapse';
import { FaqComponentProps } from '../../organisms/FaqsSection';
import cls from '@soluto-design/styles/cls';
import styles from './styles.module.scss';
import FeaturedText from '../FeaturedText';

interface AccordionProps extends FaqComponentProps {
    className?: string | boolean;
}

export default function Accordion({ title, body, show }: AccordionProps) {
    const [isOpen, setIsOpen] = useState(show);

    return (
        <div className={cls('font-b400-regular', styles.wrapper)}>
            <button
                className={cls(
                    styles.btn,
                    'display-xs-flex display-md-inline-flex align-xs-center justify-xs-start',
                )}
                onClick={() => setIsOpen(!isOpen)}>
                <span>{title}</span>
                <Icon
                    className={cls('ml-sm')}
                    icon={isOpen ? 'minus' : 'plus'}
                    size={32}
                />
            </button>
            <Collapse
                theme={{
                    collapse: styles.collapse,
                    content: styles['collapse-body'],
                }}
                isOpened={isOpen}>
                <FeaturedText size="medium" text={body} />
            </Collapse>
        </div>
    );
}
