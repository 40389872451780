import React from 'react';
import { TextFieldArea, TextFieldAreaProps } from '@soluto-design/react';

import cls from '@soluto-design/styles/cls';

import style from './styles.module.scss';

export interface TextAreaFloatProps
    extends Omit<
        TextFieldAreaProps,
        'rightIcon' | 'readOnly' | 'type' | 'rightIconButtonProps' | 'id'
    > {}

const TextAreaFloat = React.forwardRef<HTMLTextAreaElement, TextAreaFloatProps>(
    function TextAreaFloat(
        {
            help,
            error,
            success,
            value,
            placeholder,
            label,
            ...props
        }: TextAreaFloatProps,
        ref,
    ) {
        const messageInlineProps = {
            size: 'filled',
            className: style.message,
        };

        return (
            <TextFieldArea
                ref={ref}
                size="filled"
                variant="filled"
                classes={{
                    wrapper: style.root,
                    input: style.input,
                    inputWrapper: style.inputWrapper,
                    label: cls(style.label),
                }}
                label={label}
                placeholder={label}
                help={
                    help
                        ? {
                              ...messageInlineProps,
                              children: help,
                          }
                        : null
                }
                error={
                    error
                        ? {
                              ...messageInlineProps,
                              size: 'filled',
                              children: error,
                          }
                        : null
                }
                success={
                    success
                        ? {
                              ...messageInlineProps,
                              size: 'filled',
                              children: success,
                          }
                        : null
                }
                {...props}
            />
        );
    },
);

export default TextAreaFloat;
