import React, {
    createContext,
    useContext,
    useRef,
    MutableRefObject,
} from 'react';

interface State {
    previousId: MutableRefObject<string | undefined>;
}

export const VideoAsk = createContext({} as State);

export const VideoAskProvider: React.FunctionComponent = ({ children }) => {
    const previousId = useRef<string>();

    return (
        <VideoAsk.Provider value={{ previousId }}>{children}</VideoAsk.Provider>
    );
};

export const useVideoAsk = () => {
    return useContext(VideoAsk);
};
