import React from 'react';

import styles from './styles.module.scss';

export interface TouchpointTemplateProps {
    html: string;
}
export default function TouchpointTemplate({ html }: TouchpointTemplateProps) {
    return (
        <div
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: html }}
        />
    );
}
