import React from 'react';

import { Container, Title } from '@soluto-design/react';
import cls from '@soluto-design/styles/cls';
import styles from './styles.module.scss';
import TableItem, { TableItemProps } from '../../molecules/TableItem';

export interface TableSectionProps {
    title?: string;
    items: TableItemProps[];
    topSpacer?: string;
}

export default function TableSection({
    items,
    title,
    topSpacer,
}: TableSectionProps) {
    return (
        <section className={cls('mb-extra', topSpacer)}>
            <Container>
                {!!title && (
                    <Title
                        component="h2"
                        className={cls(
                            styles.title,
                            'font-b400-regular mb-xg',
                        )}>
                        {title}
                    </Title>
                )}
                {!!items && (
                    <div
                        className={cls(
                            'display-xs-flex flex-xs-column',
                            styles.table,
                        )}>
                        {items?.map((item, i) => (
                            <TableItem key={`link-${i}`} {...item} />
                        ))}
                    </div>
                )}
            </Container>
        </section>
    );
}
