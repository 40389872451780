import React from 'react';

import Link from '@soluto-design/link';
import { Text } from '@soluto-design/react';
import cls from '@soluto-design/styles/cls';
import CardBase, { CardBaseProps } from '../CardBase';

import styles from './styles.module.scss';

export interface CardMediaTextProps extends CardBaseProps {
    text: string;
}

export default function CardMediaText({
    image,
    video,
    text,
    tags,
    href,
}: CardMediaTextProps) {
    return (
        <CardBase
            href={href}
            tags={tags}
            image={image}
            video={video}
            gradient
            hasCardPostHeight>
            <Link
                href={href}
                className={cls(
                    'no-decoration display-xs-inline-block',
                    styles.text,
                )}>
                <Text
                    component="h4"
                    className={cls('font-b400-regular pr-xxs')}>
                    {text}
                </Text>
            </Link>
        </CardBase>
    );
}
