import React, { useEffect, useRef, HTMLAttributes, useCallback } from 'react';

import ReCAPTCHA from 'react-google-recaptcha';

import {
    TextFieldSimpleProps,
    TextFieldAreaProps,
    SelectionControlCheckboxProps,
    Button,
} from '@soluto-design/react';

import cls from '@soluto-design/styles/cls';

import TextFieldFloat from '../../atoms/TextFieldFloat';
import TextAreaFloat from '../../atoms/TextAreaFloat';
import Checkbox from '../../atoms/Checkbox';

import { useToast } from '../../../hooks/useToast';

import { useForm } from 'react-hook-form';

import styles from './styles.module.scss';

interface FormPropsField {
    type: 'simple' | 'area' | 'checkbox' | 'hidden' | 'legal';
    props:
        | TextFieldSimpleProps
        | TextFieldAreaProps
        | SelectionControlCheckboxProps
        | HTMLAttributes<HTMLInputElement>;
}

export interface FormProps extends HTMLAttributes<HTMLFormElement> {
    fields?: FormPropsField[];
    method?: 'POST' | 'GET';
    action?: string;
    submitText?: string;
    successText?: string;
    errorText?: string;
    requiredError?: string;
    submitVariant?: 'dark' | 'light';
    emailError?: string;
}

export default function Form({
    onSubmit,
    fields,
    submitText = 'Send',
    successText = 'Success',
    errorText = 'Error',
    submitVariant = 'light',
    requiredError = 'Campo requerido',
    emailError = 'Email no válido',
    id,
    ...props
}: FormProps) {
    const recaptchaRef = useRef();

    const { showToast } = useToast();

    const { handleSubmit, formState, reset, register } = useForm({
        mode: 'onChange',
        defaultValues: fields?.reduce((acc, field) => {
            if (field.type === 'checkbox') {
                acc[field.props.name] = false;

                return acc;
            }

            acc[field.props.name] = field.props.value ?? null;

            return acc;
        }, {}),
    });

    const {
        isSubmitSuccessful,
        isSubmitting,
        errors,
        isValid,
        isDirty,
        touchedFields,
    } = formState;

    const validateBusinessEmail = (email) => {
        const regexEmail = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

        const personalDomains = [
            'gmail.com',
            'yahoo.com',
            'hotmail.com',
            'outlook.com',
            'icloud.com',
            'aol.com',
            'protonmail.com',
            'zoho.com',
        ];

        if (!email) {
            return requiredError;
        }

        if (!regexEmail.test(email)) {
            return emailError;
        }

        const domain = email.split('@')[1];

        return personalDomains.includes(domain) ? emailError : true;
    };

    const onSubmitWithReCAPTCHA = useCallback(
        async (data, e) => {
            if (!recaptchaRef?.current) {
                return;
            }

            Object.keys(data).forEach((key) => {
                data[key] = data[key] === 'on' ? true : data[key];
            });

            let token = null;

            try {
                token = await (recaptchaRef.current as any).executeAsync();
            } catch (e) {
                console.log(e);
            }

            if (!token) {
                showToast(errorText);
                reset();
                return;
            }

            const form = e.target;

            try {
                const res = await fetch(form.action, {
                    method: form.method || 'POST',
                    body: JSON.stringify({
                        ...data,
                        'g-recaptcha-response': token,
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                });

                await res.json();

                showToast(res?.status === 200 ? successText : errorText);
                recaptchaRef.current.reset();
            } catch (e) {
                showToast(errorText);
                recaptchaRef.current.reset();
            }
        },
        [errorText, showToast, successText, reset],
    );

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset();
        }
    }, [isSubmitSuccessful, reset]);

    const legalField = fields?.find((field) => field?.type === 'legal');

    return (
        <form onSubmit={handleSubmit(onSubmitWithReCAPTCHA)} {...props}>
            <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey="6LfbgnUnAAAAAElNn4Z_xp7t8Is1JnVDg7Cz_Ak_"
            />
            {fields?.map((field, i) => {
                const {
                    id: fieldId,
                    name,
                    label,
                    type,
                    placeholder,
                    required,
                } = field.props;

                switch (field.type) {
                    case 'simple':
                        return (
                            <TextFieldFloat
                                {...(field.props as TextFieldSimpleProps)}
                                {...register(name, {
                                    required: required ? requiredError : false,
                                    ...(type === 'email' && {
                                        validate: validateBusinessEmail,
                                    }),
                                })}
                                key={`${id}-${fieldId}`}
                                className={styles.text}
                                label={placeholder ?? label}
                                id={`${id}-${fieldId}`}
                                error={
                                    touchedFields?.[name] && errors?.[name]
                                        ? errors[name]?.message
                                        : false
                                }
                            />
                        );
                    case 'area':
                        return (
                            <TextAreaFloat
                                {...(field.props as TextFieldAreaProps)}
                                {...register(name, {
                                    required: required ? requiredError : false,
                                })}
                                id={`${id}-${fieldId}`}
                                key={`${id}-${fieldId}`}
                                className={styles.text}
                                label={placeholder ?? label}
                                error={
                                    touchedFields?.[name] && errors?.[name]
                                        ? errors[name]?.message
                                        : false
                                }
                            />
                        );
                    case 'checkbox':
                        return (
                            <Checkbox
                                {...(field.props as SelectionControlCheckboxProps)}
                                {...register(name, {
                                    required,
                                })}
                                id={`${id}-${fieldId}`}
                                key={`${id}-${fieldId}`}
                                className="mt-xg"
                                error={
                                    touchedFields?.[name] && errors?.[name]
                                        ? errors[name]?.message
                                        : false
                                }
                            />
                        );

                    case 'hidden':
                        return (
                            <input
                                {...(field.props as HTMLAttributes<HTMLInputElement>)}
                                {...register(name)}
                                key={`${id}-${fieldId}`}
                                type="hidden"
                                id={`${id}-${fieldId}`}
                            />
                        );
                    default:
                        return null;
                }
            })}
            <div className={styles.wrapperActions}>
                {legalField && (
                    <div>
                        <Checkbox
                            {...(legalField.props as SelectionControlCheckboxProps)}
                            {...register(legalField?.props?.name, {
                                required: legalField?.props?.required,
                            })}
                            id={`${id}-${legalField?.props?.id}`}
                            className="font-c300"
                            error={
                                touchedFields?.[legalField?.props?.name] &&
                                errors?.[legalField?.props?.name]
                                    ? errors[legalField?.props?.name]?.message
                                    : false
                            }
                        />
                    </div>
                )}
                <div className={styles.wrapperButton}>
                    <Button
                        component="button"
                        type="submit"
                        variant={
                            submitVariant === 'dark' ? 'secondary' : 'primary'
                        }
                        size="text"
                        className={cls(styles.submitButton)}
                        {...(!!isSubmitting && {
                            disabled: true,
                        })}>
                        {submitText}
                    </Button>
                </div>
            </div>
        </form>
    );
}
