import React, { HTMLAttributes, useMemo, useEffect, useState } from 'react';
import { Title, Container, Icon } from '@soluto-design/react';
import cls from '@soluto-design/styles/cls';
import styles from './styles.module.scss';
import useNearScreen from '../../../hooks/useNearScreen';
import BandText, { BandTextProps } from '../../molecules/BandText';

export interface HeroSectionProps
    extends Omit<HTMLAttributes<HTMLElement>, 'title'> {
    size?: 'small' | 'medium' | 'large';
    title?: string;
    animated?: boolean;
    band?: BandTextProps;
}

export default function HeroSection({ title, animated, band, size = 'small' }) {
    const [start, setStart] = useState(false);
    const { isNearScreen, fromRef } = useNearScreen({ threshold: 1 }, false);

    const [titleElement, itemsNr] = useMemo(() => {
        if (!animated) {
            return [title, 1];
        }

        const parts = `${title}`
            .split(/\r?\n/)
            .map((p) => p.trim())
            .filter((p) => !!p);
        return [
            parts.map((p) => `<span data-animation>${p}</span>`).join(''),
            parts.length,
        ];
    }, [title, animated]);

    useEffect(() => {
        if (!animated) {
            return;
        }

        if (isNearScreen) {
            setStart(true);
            const delay = itemsNr === 3 ? 17000 : itemsNr === 2 ? 13500 : 10000;

            let interval = setInterval(() => {
                setStart(false);
                setTimeout(() => {
                    setStart(true);
                }, 500);
            }, delay);

            return () => {
                clearInterval(interval);
            };
        } else {
            setStart(false);
        }
    }, [isNearScreen, itemsNr, setStart, animated]);

    return (
        <section
            data-theme="black"
            className={cls(styles.root, styles[`root-${size}`])}>
            <div
                className={cls(
                    styles.content,
                    'display-xs-flex justify-xs-center',
                    title ? 'align-xs-center' : 'align-xs-start',
                )}>
                {!!band && (
                    <BandText
                        {...band}
                        className={cls(
                            styles.band,
                            !isNearScreen && styles.hidden,
                        )}
                    />
                )}
                {!!title && (
                    <Container>
                        <Title
                            isHtml
                            component="h1"
                            data-items={itemsNr}
                            className={cls(
                                'font-h200 text-uppercase',
                                styles.title,
                                start && styles.start,
                            )}>
                            {titleElement}
                        </Title>
                        {animated && (
                            <>
                                <Icon
                                    icon="brand-isotype"
                                    data-animation
                                    className={cls(styles.icon, styles.first)}
                                />
                                <Icon
                                    icon="brand-isotype"
                                    data-animation
                                    className={cls(styles.icon, styles.last)}
                                />
                            </>
                        )}
                    </Container>
                )}
            </div>
            <div ref={fromRef} className={styles.animatedMark} />
        </section>
    );
}
