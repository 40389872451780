import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import { Button, Text } from '@soluto-design/react';

import cls from '@soluto-design/styles/cls';

import styles from './styles.module.scss';

export interface ToastProps {
    children: React.ReactNode;
    visible: boolean;
    onClose?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    position?: 'top' | 'bottom';
}

const Toast: React.FC<ToastProps> = ({
    children,
    visible,
    onClose,
    position = 'bottom',
}) => {
    return (
        <AnimatePresence>
            {visible && (
                <motion.div
                    initial={{ opacity: 0, y: '100%' }}
                    animate={{ opacity: 1, y: -64 }}
                    exit={{ opacity: 0, y: '100%' }}
                    transition={{
                        duration: 0.3,
                        ease: 'easeInOut',
                    }}
                    className={styles.containerToast}>
                    <div
                        data-theme="black"
                        className={cls(
                            'background-secondary',
                            'radius-medium',
                            'text-primary',
                            'display-xs-flex',
                            'align-xs-center',
                            'elevation-small',
                            'justify-xs-between',
                            styles.wrapperToast,
                            styles?.[position],
                        )}>
                        <Text
                            component="p"
                            className={cls(
                                'font-c200-regular',
                                'text-primary',
                            )}>
                            {children}
                        </Text>
                        <div>
                            <Button
                                component="button"
                                variant="secondary-no-rotate"
                                size="icon"
                                rightIcon="close"
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (typeof onClose === 'function')
                                        onClose(e);

                                    return;
                                }}
                            />
                        </div>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default Toast;
