import React from 'react';
import { Title, Button } from '@soluto-design/react';
import Link from '@soluto-design/link';
import Picture, { PictureProps } from '@soluto-design/picture';
import cls from '@soluto-design/styles/cls';

import styles from './styles.module.scss';

export interface CaseStudyProps {
    title: string;
    client?: string;
    slug: string;
    featuredImage: PictureProps;
}

export default function CaseStudy({
    title,
    client,
    slug,
    featuredImage,
}: CaseStudyProps) {
    return (
        <article className={cls(styles.wrapper)}>
            {featuredImage?.file ? (
                <Link
                    href={slug}
                    title={title}
                    className={cls('no-decoration')}>
                    <Picture
                        alt={title}
                        src={featuredImage.file}
                        sizes="(max-width: 414px) 732px, (max-width: 1023px) 1336px, 2720px"
                    />
                </Link>
            ) : null}
            <div
                className={cls(
                    'mt-sm display-xs-flex flex-xs-column align-xs-start flex-md-row align-md-center',
                )}>
                <Link
                    href={slug}
                    title={title}
                    className={cls('no-decoration', styles.title)}>
                    <Title className="font-b400-regular" component="h4">
                        {title}
                    </Title>
                </Link>
                <Button href={slug} variant="secondary" size="text">
                    {client}
                </Button>
            </div>
        </article>
    );
}
