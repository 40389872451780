import React from 'react';
import BandText, { BandTextProps } from '../../molecules/BandText';
import styles from './styles.module.scss';
import cls from '@soluto-design/styles/cls';

export interface BandTextSectionProps extends BandTextProps {}

export default function BandTextSection({
    className,
    ...props
}: BandTextSectionProps) {
    return (
        <section className={cls('pb-extra', className)}>
            <BandText kind="text-small" className={styles.band} {...props} />
        </section>
    );
}
