import React from 'react';

import { Button, Text, Title } from '@soluto-design/react';
import cls from '@soluto-design/styles/cls';
import Link, { navigate, LinkProps } from '@soluto-design/link';
import Picture, { PictureProps } from '@soluto-design/picture';

import styles from './styles.module.scss';

export interface TableItemProps extends LinkProps {
    image: PictureProps;
    title?: string;
    caption?: string;
}

export default function TableItem({
    image,
    caption,
    title,
    href,
}: TableItemProps) {
    const onClickTableItem = () => {
        navigate(href);
    };

    return (
        <div
            onClick={onClickTableItem}
            tabIndex="0"
            role="button"
            data-theme="white"
            className={cls(
                styles.root,
                'display-xs-flex ',
                'font-b500-regular py-xs px-xs pr-modulor radius-medium',
            )}>
            <div
                className={cls(styles.left, 'display-xs-flex align-xs-center')}>
                {!!image && (
                    <div
                        className={cls(
                            'position-xs-relative mr-sm',
                            styles.imageWrapper,
                        )}>
                        <Picture
                            layout="fill"
                            src={image}
                            alt={image?.alt || title}
                        />
                    </div>
                )}
                <Text
                    component="span"
                    className={cls(
                        'display-xs-none display-lg-flex mr-md',
                        'font-b500-regular text-tertiary',
                    )}>
                    {caption}
                </Text>
            </div>
            <div
                className={cls(
                    styles.right,
                    'display-xs-flex align-xs-center justify-xs-between',
                )}>
                <Link href={href} className={cls('no-decoration')}>
                    <Title component="h4">{title}</Title>
                </Link>
                <Button
                    variant="secondary"
                    size="icon"
                    component="span"
                    rightIcon="arrow-corner--type-right-up"
                    className={cls('ml-md', styles.button)}
                />
            </div>
        </div>
    );
}
