import React, { Fragment, HTMLAttributes, useMemo } from 'react';

import { Button, Text, Title, Icon } from '@soluto-design/react';
import Picture, { PictureProps } from '@soluto-design/picture';
import cls from '@soluto-design/styles/cls';

import styles from './styles.module.scss';

interface ResumeBlockProps extends HTMLAttributes<HTMLDivElement> {
    type: 'text' | 'people' | 'share';
    label?: string;
    labelClassName?: string;
    variant?: 'small' | 'large';
}

interface ResumeBlockTextProps extends ResumeBlockProps {
    text: string;
}

interface ResumeBlockPersonProps {
    image?: PictureProps;
    name: string;
    position?: string;
}

interface ResumeBlockPeopleProps extends ResumeBlockProps {
    people: ResumeBlockPersonProps[];
}

interface ResumeBlockShareProps extends ResumeBlockProps {
    url: string;
    title?: string;
    xShareLabel?: string;
    linkedinShareLabel?: string;
    copyLabel?: string;
}

export interface ResumeProps {
    blocks: (
        | ResumeBlockTextProps
        | ResumeBlockPeopleProps
        | ResumeBlockShareProps
    )[];
}

function ResumeBlock({
    label,
    children,
    className,
    labelClassName,
    variant,
    ...props
}: ResumeBlockProps) {
    return (
        <div className={cls(styles.block, className)} {...props}>
            {!!label && (
                <Title
                    component="h5"
                    className={cls(
                        'font-c200-regular text-tertiary',
                        styles.label,
                        labelClassName,
                        variant === 'large' && styles.labelLarge,
                    )}>
                    {label}
                </Title>
            )}
            {children}
        </div>
    );
}

function ResumeBlockText({ text, ...props }: ResumeBlockTextProps) {
    return (
        <ResumeBlock {...props}>
            <Text component="p" className={cls('font-c100-regular')}>
                {text}
            </Text>
        </ResumeBlock>
    );
}

function ResumeBlockPerson({ image, name, position }: ResumeBlockPersonProps) {
    return (
        <div
            className={cls(
                styles.person,
                'display-xs-flex background-secondary',
            )}>
            <div
                className={cls(
                    'mr-xxs position-xs-relative background-disabled',
                    styles.image,
                )}>
                {!!image && <Picture layout="fill" src={image} alt={name} />}
            </div>
            <div className={styles.personInfo}>
                <Text
                    component="p"
                    className={cls('font-c200-regular text-primary')}>
                    {name}
                </Text>
                {!!position && (
                    <Text
                        component="span"
                        className={cls('font-c200-regular text-tertiary')}>
                        {position}
                    </Text>
                )}
            </div>
        </div>
    );
}

function ResumeBlockPeople({ people, ...props }: ResumeBlockPeopleProps) {
    return (
        <ResumeBlock
            labelClassName={cls('mb-xs', styles.separator, styles.peopleLabel)}
            {...props}>
            {people.map((person, i) => (
                <ResumeBlockPerson key={i} {...person} />
            ))}
        </ResumeBlock>
    );
}

function isMobile() {
    if (typeof window === 'undefined') {
        return false;
    }

    const regex =
        /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return regex.test(window.navigator.userAgent);
}

function ResumeBlockShare({
    url,
    label,
    title,
    xShareLabel,
    linkedinShareLabel,
    copyLabel,
    variant,
    ...props
}: ResumeBlockShareProps) {
    const nativeShare = useMemo(
        () =>
            typeof window !== 'undefined' &&
            typeof (window as any)?.navigator?.share !== 'undefined',
        [],
    );

    const showOnlyNativeShare = useMemo(() => {
        if (typeof window === 'undefined') {
            return false;
        }

        return nativeShare && isMobile();
    }, [nativeShare]);

    return (
        <ResumeBlock
            labelClassName={cls(
                'mb-xs',
                styles.separator,
                styles.shareLabel,
                variant === 'large' && styles.shareLabelLarge,
            )}
            className={cls(variant === 'large' && styles.shareBlock)}
            {...(!showOnlyNativeShare && { label })}
            {...props}>
            {showOnlyNativeShare ? (
                <Button
                    component="button"
                    variant="secondary-no-rotate"
                    size="text"
                    title={label || 'Share'}
                    onClick={() => {
                        window.navigator.share({ url, title });
                    }}
                    rightIcon="share">
                    {label}
                </Button>
            ) : (
                <div
                    className={cls(
                        'display-xs-flex justify-xs-start align-xs-center',
                        styles.shareIcons,
                    )}>
                    <Button
                        variant="secondary-no-rotate"
                        size="icon"
                        title={linkedinShareLabel || 'Share on Linkedin'}
                        rel="noopener noreferrer"
                        target="_blank"
                        href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                            url,
                        )}`}
                        leftIcon="linkedin"
                    />
                    <Button
                        title={xShareLabel || 'Share on X'}
                        variant="secondary-no-rotate"
                        size="icon"
                        rel="noopener noreferrer"
                        target="_blank"
                        href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                            [title || 'Check', url].join(`\n`),
                        )}`}
                        leftIcon="twitter"
                    />
                    <Button
                        component="button"
                        variant="secondary-no-rotate"
                        size="icon"
                        {...(nativeShare
                            ? {
                                  title: label || 'Share',
                                  onClick: () => {
                                      window.navigator.share({ url, title });
                                  },
                              }
                            : {
                                  title: copyLabel || 'Copy URL to share',
                                  onClick: () => {
                                      navigator.clipboard.writeText(url);
                                  },
                              })}
                        leftIcon="link"
                    />
                </div>
            )}
        </ResumeBlock>
    );
}

export default function Resume({ blocks }: ResumeProps) {
    return (
        <Fragment>
            {blocks.map((item, i) => {
                switch (item.type) {
                    case 'text':
                        return (
                            <ResumeBlockText
                                key={i}
                                {...(item as ResumeBlockTextProps)}
                            />
                        );
                    case 'people':
                        return (
                            <ResumeBlockPeople
                                key={i}
                                {...(item as ResumeBlockPeopleProps)}
                            />
                        );
                    case 'share':
                        return (
                            <ResumeBlockShare
                                key={i}
                                {...(item as ResumeBlockShareProps)}
                            />
                        );
                    default:
                        return null;
                }
            })}
        </Fragment>
    );
}
