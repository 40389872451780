import React, { useMemo, memo, useState, useEffect } from 'react';

import {
    default as BaseVideo,
    VideoProps as BaseVideoProps,
} from '../../tech/Video';
import cls from '@soluto-design/styles/cls';

import styles from './styles.module.scss';

export interface VideoSourceProps {
    isCloudinary?: boolean;
    file: {
        publicURL: string;
        extension: string;
    };
}

export interface VideoProps {
    video?: VideoSourceProps | undefined;
    externalVideo?: {
        title?: string;
        height?: number;
        width?: number;
        provider: string;
        providerUid: string;
        printUrl?: string;
    };
    id?: string | undefined;
    className?: string;
    playsInline?: BaseVideoProps['playsInline'];
}

function getWidthFromScreenWidth(screenWidth: number) {
    if (screenWidth < 768) {
        return 1024;
    } else if (screenWidth < 1024) {
        return 1280;
    } else if (screenWidth < 1280) {
        return 1440;
    } else if (screenWidth < 1440) {
        return 1680;
    } else {
        return 1920;
    }
}

function getTypeOfConnection() {
    if (typeof window === 'undefined') {
        return 'wifi';
    }

    const connection =
        window.navigator.connection ||
        window.navigator.mozConnection ||
        window.navigator.webkitConnection;
    if (connection) {
        return connection.effectiveType;
    }

    return 'wifi';
}

function getCloudinarySources(
    url: string,
    extension: string,
    screenWidth?: number,
) {
    const width = getWidthFromScreenWidth(screenWidth ? screenWidth : 0);

    let q_auto = '';
    let formats = ['mp4', 'webm'];

    if (width <= 1024) {
        const connectionType = getTypeOfConnection();
        if (['slow-2g', '2g', '3g'].includes(connectionType)) {
            q_auto = 'q_auto:eco';
            formats = ['webm', 'mp4'];
        }
    } else if (width > 1440) {
        q_auto = 'q_auto:best';
    }

    const resized_url = url.replace(
        '/video/upload/',
        `/video/upload/c_scale,w_${width}/${q_auto ? `${q_auto}/` : ''}`,
    );

    return formats.map((source) => ({
        src: resized_url.replace(`.${extension}`, `.${source}`),
        type: source,
    })) as BaseVideoProps['sources'];
}

const Video: React.FunctionComponent<VideoProps> = memo(function Video({
    id,
    video,
    externalVideo,
    className,
    playsInline,
}) {
    const [sources, setSources] = useState<BaseVideoProps['sources']>(() => {
        if (!video?.file?.publicURL) {
            return [];
        }

        if (!video.isCloudinary) {
            return [
                {
                    src: video?.file?.publicURL,
                    type: video?.file?.extension,
                },
            ];
        }

        return getCloudinarySources(video.file.publicURL, video.file.extension);
    });

    const printVideo = useMemo(() => {
        if (externalVideo?.printUrl) {
            return externalVideo.printUrl;
        }
        let printUrl = '';

        switch (externalVideo?.provider) {
            case 'youtube':
                printUrl = `//www.youtube.com/embed/${externalVideo.providerUid}?rel=0`;
                break;
            case 'vimeo':
                printUrl = `//player.vimeo.com/video/${externalVideo.providerUid}`;
                break;
        }

        return printUrl;
    }, [externalVideo]);

    useEffect(() => {
        if (
            !video?.file?.publicURL ||
            !video.isCloudinary ||
            typeof window === 'undefined'
        ) {
            return;
        }

        const onResize = () => {
            setSources(
                getCloudinarySources(
                    video.file.publicURL,
                    video.file.extension,
                    window.innerWidth,
                ),
            );
        };

        onResize();

        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [video, setSources]);

    return externalVideo?.providerUid ? (
        <div
            className={cls(styles['video-external'], className)}
            {...(externalVideo?.height &&
                externalVideo.width && {
                    style: {
                        paddingBottom: `${
                            (externalVideo.height / externalVideo.width) * 100
                        }%`,
                    },
                })}>
            <iframe
                id={externalVideo.providerUid}
                title={externalVideo.title}
                src={printVideo}
                width="100%"
                height="100%"
            />
        </div>
    ) : sources?.length ? (
        <BaseVideo
            id={id}
            playsInline={playsInline ?? true}
            sources={sources}
            className={cls(styles.video, className)}
        />
    ) : null;
});

export default Video;
