import React, { useState } from 'react';

import { Icon } from '@soluto-design/react';

import cls from '@soluto-design/styles/cls';

import styles from './styles.module.scss';

interface CollapseProps {
    label: string;
    children: React.ReactNode;
}

const Collapse = ({ label, children }: CollapseProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = () => {
        setIsOpen((prevState) => !prevState);
    };

    return (
        <div>
            <button
                onClick={handleClick}
                className={cls(
                    'text-tertiary font-c100 display-xs-flex align-xs-center pt-2 mx-2 cursor-pointer',
                    styles.label,
                )}>
                {label}
                <Icon
                    icon="arrow-chevron--type-down"
                    className={cls(styles.icon, isOpen && styles.opened)}
                />
            </button>
            <div className={cls(styles.collapse, isOpen && styles.opened)}>
                <div className={styles.content}>
                    <div className={cls('pt-sm')}>{children}</div>
                </div>
            </div>
        </div>
    );
};

export default Collapse;
