import React from 'react';
import { Button, Container } from '@soluto-design/react';
import cls from '@soluto-design/styles/cls';

import styles from './styles.module.scss';

import { Category } from '../../../types';
import SectionTheme from '../../molecules/SectionTheme';

export interface ActivableCategory extends Category {
    active: boolean;
}

export interface BlogFiltersSectionProps {
    categories: ActivableCategory[];
    onClick?: (category: Category) => void;
    className?: string;
}

export default function BlogFiltersSection({
    categories,
    onClick,
    className,
    ...props
}: BlogFiltersSectionProps) {
    return (
        <SectionTheme {...props} className={cls(className)}>
            <Container>
                <div className={cls(styles['tags'])}>
                    {categories.map((tag, i) => (
                        <Button
                            key={`tag-${i}`}
                            {...(!!onClick && {
                                onClick: (e) => {
                                    e.preventDefault();
                                    onClick(tag);
                                },
                            })}
                            href={tag?.slug}
                            className={cls(!!tag.active && styles.activeTag)}
                            size="tag-large"
                            variant="tag-filter">
                            {tag.title}
                        </Button>
                    ))}
                </div>
            </Container>
        </SectionTheme>
    );
}
