import React, {
    HTMLAttributes,
    useRef,
    useEffect,
    useState,
    useCallback,
    MouseEvent,
    KeyboardEvent,
} from 'react';

import { Container, Button, useIsBreakpointUp } from '@soluto-design/react';
import Video, { VideoSourceProps } from '../../molecules/Video';
import useDisableScroll from '../../../hooks/useDisableScroll';
import Portal from '@soluto-design/react/dist/components/Portal';
import Cursor from '../../atoms/Cursor';
import type { LinkProps } from '@soluto-design/link';

import cls from '@soluto-design/styles/cls';
import styles from './styles.module.scss';

export interface MediaSectionProps extends HTMLAttributes<HTMLElement> {
    mobileShort?: VideoSourceProps;
    desktopShort?: VideoSourceProps;
    fullMedia?: VideoSourceProps;
    showLabel?: string;
    closeLabel?: string;
    link?: LinkProps;
    pb?: string;
}

export default function MediaSection({
    mobileShort,
    desktopShort,
    fullMedia,
    showLabel = 'Watch video',
    closeLabel = 'Close video',
    link,
    className,
    pb,
    ...props
}: MediaSectionProps) {
    const fullWrapper = useRef<HTMLDivElement>(null);
    const [showFull, setShowFull] = useState(false);
    const [isMounted, setIsMounted] = useState(false);
    const { disableScroll, enableScroll } = useDisableScroll();
    const isDesktop = useIsBreakpointUp('lg');

    useEffect(() => setIsMounted(true), []);

    const showFullDesktopVideo = useCallback(
        ({ currentTarget }: MouseEvent) => {
            if (!fullWrapper?.current) {
                return;
            }

            const rect: any = (
                currentTarget as HTMLElement
            ).getBoundingClientRect();

            fullWrapper.current.style.setProperty('--fx', `${rect.x}px`);
            fullWrapper.current.style.setProperty('--fy', `${rect.y}px`);
            fullWrapper.current.style.setProperty('--fw', `${rect.width}px`);
            fullWrapper.current.style.setProperty('--fh', `${rect.height}px`);
            fullWrapper.current.style.setProperty('display', 'block');

            setTimeout(() => {
                if (fullWrapper.current) {
                    fullWrapper.current.dataset.transition = 'true';
                }
                setShowFull(true);
            }, 0);
        },
        [setShowFull, fullWrapper],
    );

    const hideFullDesktopVideo = useCallback(() => {
        if (!fullWrapper.current) {
            return;
        }

        fullWrapper.current.addEventListener(
            'transitionend',
            function () {
                delete this.dataset.transition;
                this.style.removeProperty('--fx');
                this.style.removeProperty('--fy');
                this.style.removeProperty('--fw');
                this.style.removeProperty('--fh');
                this.style.removeProperty('display');
            },
            { once: true },
        );

        setShowFull(false);
    }, [setShowFull, fullWrapper]);

    const showFullMobileVideo = useCallback(() => {
        if (!fullWrapper?.current) {
            return;
        }

        const videoWrapper = fullWrapper.current.querySelector('video')
            ?.parentNode as HTMLDivElement;
        if (!videoWrapper) {
            return;
        }

        const videoHtml = videoWrapper.innerHTML;
        videoWrapper.querySelector('video')?.remove();

        videoWrapper.innerHTML = videoHtml;
        const video = videoWrapper.querySelector('video') as HTMLVideoElement;

        setTimeout(() => {
            video.play().catch((err) => {
                // console.log(err);
            });

            if (video.requestFullscreen) {
                video.requestFullscreen();
            } else if (video.mozRequestFullScreen) {
                video.mozRequestFullScreen();
            } else if (video.webkitRequestFullscreen) {
                video.webkitRequestFullscreen();
            } else if (video.msRequestFullscreen) {
                video.msRequestFullscreen();
            } else if (video.webkitEnterFullscreen) {
                video.webkitEnterFullscreen();
            }
        }, 100);
    }, [fullWrapper]);

    const onKeyDown = useCallback(
        ({ key }: KeyboardEvent) => {
            if (key === 'Escape') {
                hideFullDesktopVideo();
            }
        },
        [hideFullDesktopVideo],
    );

    useEffect(() => {
        if (!fullWrapper?.current) {
            return;
        }

        const video: HTMLVideoElement | null =
            fullWrapper.current.querySelector('video');
        if (!video) {
            return;
        }

        if (!showFull) {
            enableScroll();
            delete window.document.body.dataset.disableBlackboard;
            video.removeEventListener('ended', hideFullDesktopVideo);
            window.removeEventListener('keydown', onKeyDown);
            video.pause();
        } else {
            disableScroll();
            window.document.body.dataset.disableBlackboard = 'true';
            video.addEventListener('ended', hideFullDesktopVideo);
            window.addEventListener('keydown', onKeyDown);
            video.play().catch((err) => {
                // console.log(err);
            });
        }
    }, [
        showFull,
        fullWrapper,
        disableScroll,
        enableScroll,
        hideFullDesktopVideo,
        onKeyDown,
    ]);

    return (
        <section
            {...props}
            className={cls(className, 'pt-xxl', `pb-${pb || 'xxxl'}`)}>
            <Container>
                <div className={cls('position-xs-relative')}>
                    {!!mobileShort && (
                        <Video
                            className={cls(
                                styles.short,
                                'radius-medium display-lg-none',
                            )}
                            video={mobileShort}
                        />
                    )}
                    {!!desktopShort && (
                        <Video
                            className={cls(
                                styles.short,
                                'radius-medium display-xs-none display-lg-block',
                            )}
                            video={desktopShort}
                        />
                    )}
                    {!!fullMedia && isDesktop && (
                        <Cursor
                            onClick={
                                isDesktop
                                    ? showFullDesktopVideo
                                    : showFullMobileVideo
                            }
                            component="button"
                            buttonLabel={showLabel}
                            dataTheme="black"
                        />
                    )}
                    {!!link && (
                        <Button
                            className={styles.link}
                            variant="secondary"
                            size="text"
                            rightIcon="arrow-corner--type-right-up"
                            {...link}
                        />
                    )}
                </div>
            </Container>
            {!!fullMedia && isMounted && (
                <Portal id="media">
                    <div
                        ref={fullWrapper}
                        data-theme="black"
                        className={cls(
                            styles.fullWrapper,
                            showFull && styles.shown,
                        )}>
                        <Video
                            className={styles.full}
                            video={fullMedia}
                            playsInline={false}
                        />
                        <Cursor
                            onClick={hideFullDesktopVideo}
                            component="button"
                            buttonLabel={closeLabel}
                            dataTheme="black"
                        />
                    </div>
                </Portal>
            )}
        </section>
    );
}
