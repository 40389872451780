import React from 'react';
import { Text, TextProps, Button } from '@soluto-design/react';
import { LinkProps } from '@soluto-design/link';
import cls from '@soluto-design/styles/cls';
import styles from './styles.module.scss';

export interface BlockTextProps extends TextProps {
    button?: LinkProps;
}

export default function BlockText({
    button,
    className,
    isHtml,
    ...textProps
}: BlockTextProps) {
    return (
        <div className={cls(styles.root, className, 'mt-sm mb-xxl')}>
            {!!button && (
                <Button
                    variant="secondary"
                    size="text"
                    rightIcon="arrow-corner--type-right-up"
                    {...button}
                    className={cls('mb-md', styles.button, button.className)}
                />
            )}
            <Text
                {...(isHtml
                    ? { isHtml: true, component: 'div' }
                    : { component: 'p' })}
                {...textProps}
                className={cls(
                    'font-b100-regular display-xs-block',
                    styles.text,
                )}
            />
        </div>
    );
}
