import React, { useEffect } from 'react';
import cls from '@soluto-design/scss/cls';
import useNearScreen from '../../../hooks/useNearScreen';

export interface VideoSourceProps {
    src: string;
    type: 'webm' | 'mp4' | 'mov';
}

export interface VideoProps {
    className?: string;
    classes?: {
        wrapper?: string;
        video?: string;
    };
    sources?: VideoSourceProps[];
    id?: string;
    playsInline?: boolean;
}

export default function Video({
    sources,
    classes,
    id,
    playsInline,
    className,
}: VideoProps) {
    const { isNearScreen, fromRef } = useNearScreen({ threshold: 0.2 });

    useEffect(() => {
        if (
            !fromRef?.current ||
            typeof window === 'undefined' ||
            !playsInline ||
            !isNearScreen
        ) {
            return;
        }

        const video = (fromRef.current as HTMLElement).querySelector('video');
        if (video) {
            video.play().catch((err) => {
                // console.log(err);
            });
        }
    }, [fromRef, isNearScreen, playsInline]);

    return sources && sources.length ? (
        <div
            ref={fromRef}
            className={cls(className, classes?.wrapper)}
            dangerouslySetInnerHTML={{
                __html: `
            <video ${id ? `id="${id}"` : ''} class="${cls(classes?.video)}" ${
                    playsInline
                        ? 'preload loop muted playsinline'
                        : 'controls controlslist="nodownload" disablepictureinpicture'
                }>
                ${sources
                    .map(
                        (s) => `
                    <source
                        src="${s.src}"
                        type="video/${s.type}"
                    />
                `,
                    )
                    .join('')}
            </video>`,
            }}
        />
    ) : null;
}
