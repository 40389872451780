import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Text, Button } from '@soluto-design/react';
import cls from '@soluto-design/styles/cls';

import { Collapse } from 'react-collapse';

import styles from './styles.module.scss';

import useDisableScroll from '../../../hooks/useDisableScroll';

export interface ScreenshotTranslations {
    title: string;
    submit: string;
    retry: string;
    uploading: string;
    cancel: string;
    text: string;
    terms: string;
    errorMessage: string;
    successMessage: string;
}

interface ShareScreenshotComponentScreenDataProps {
    width: number;
    height: number;
    scroll: number;
    path: string;
    fullHeight: number;
    svg: string;
    iframeUrl: string;
}

export interface ShareScreenshotComponentBaseProps {
    status?: string | null;
    screenData: ShareScreenshotComponentScreenDataProps;
    toggleSharing?: any;
    submit?: any;
    isSharing: boolean;
}

export interface ShareScreenshotComponentProps
    extends ShareScreenshotComponentBaseProps {
    translations: ScreenshotTranslations;
}

export default function ShareScreenshotComponent({
    screenData,
    toggleSharing,
    submit,
    status,
    translations,
    isSharing,
}: ShareScreenshotComponentProps) {
    const iframeWrapperRef = useRef<HTMLDivElement>(null);
    const [showIframe, setShowIframe] = useState(false);
    const [iframeLoaded, setIframeLoaded] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const { disableScroll, enableScroll } = useDisableScroll();

    const getWrapperStyle = useCallback(() => {
        if (
            typeof window === 'undefined' ||
            !screenData?.svg ||
            !screenData?.height ||
            !screenData?.width ||
            !iframeWrapperRef?.current
        ) {
            return {};
        }

        return {
            paddingBottom: `${(screenData.height / screenData.width) * 100}%`,
            '--tmp-pt': `${(screenData.fullHeight / screenData.width) * 100}%`,
            '--tmp-bg': `url("data:image/svg+xml,${screenData.svg
                .replace(/\"/g, `'`)
                .replace(/\</g, '%3C')
                .replace(/\>/g, '%3E')
                .replace(/\#/g, '%23')}")`,
            '--tmp-ty': `-${
                (screenData.scroll / screenData.fullHeight) * 100
            }%`,
            '--iframe-width': `${screenData.width}px`,
            '--iframe-height': `${screenData.height}px`,
            '--iframe-scale':
                iframeWrapperRef.current.offsetWidth / screenData.width,
        };
    }, [screenData, iframeWrapperRef]);

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        let timeout: any = null;

        if (status === 'share') {
            timeout = setTimeout(() => {
                setShowIframe(true);
                timeout = null;
            }, 200);
        } else if (status === 'share-success') {
            setShowSuccess(true);
        } else if (!isSharing) {
            timeout = setTimeout(() => {
                setShowIframe(false);
                setIframeLoaded(false);
                timeout = null;
            }, 200);
        }

        return () => {
            if (timeout) {
                window.clearInterval(timeout);
                timeout = null;
            }
        };
    }, [setShowIframe, setShowSuccess, setIframeLoaded, status, isSharing]);

    useEffect(() => {
        if (showSuccess && isSharing) {
            let timeout: any = setTimeout(() => {
                toggleSharing();
            }, 3000);

            return () => {
                if (timeout) {
                    window.clearInterval(timeout);
                    timeout = null;
                }
            };
        }
    }, [setShowSuccess, showSuccess, toggleSharing, isSharing]);

    useEffect(() => {
        if (isSharing) {
            disableScroll();
            setShowSuccess(false);

            return () => {
                enableScroll();
            };
        } else {
            enableScroll();
        }
    }, [isSharing, disableScroll, enableScroll, setShowSuccess]);

    useEffect(() => {
        if (typeof window === 'undefined' || !isSharing) {
            return;
        }

        window.addEventListener('@bb/reset', toggleSharing);

        return () => {
            window.removeEventListener('@bb/reset', toggleSharing);
        };
    }, [toggleSharing, isSharing]);

    return (
        <Collapse
            theme={{
                collapse: cls(
                    styles.share,
                    'elevation-small',
                    isSharing && styles.open,
                ),
                content: cls(
                    styles.shareBody,
                    'background-primary text-primary',
                ),
            }}
            isOpened={isSharing}>
            <div
                className={cls(
                    styles.imageWrapper,
                    showSuccess && 'display-xs-none',
                )}>
                <div
                    className={cls(
                        styles.image,
                        'radius-small',
                        iframeLoaded && styles.loaded,
                    )}
                    ref={iframeWrapperRef}
                    style={getWrapperStyle()}>
                    {showIframe && screenData?.iframeUrl ? (
                        <iframe
                            onLoad={() => {
                                setIframeLoaded(true);
                            }}
                            frameBorder={0}
                            title="preview"
                            src={screenData.iframeUrl}
                        />
                    ) : null}
                </div>
            </div>
            <div
                className={cls(
                    styles.textWrapper,
                    showSuccess && 'display-xs-none',
                )}>
                {translations?.title && (
                    <Text
                        className={cls(
                            'font-b500-regular text-primary display-xs-block',
                            styles.title,
                        )}>
                        {translations?.title}
                    </Text>
                )}
                {translations?.text && (
                    <Text
                        isHtml
                        className={cls(
                            'font-c200-regular text-secondary display-xs-block',
                            styles.text,
                        )}>
                        {translations?.text}
                    </Text>
                )}
            </div>
            {translations?.terms && (
                <div
                    className={cls(
                        styles.termsWrapper,
                        showSuccess && 'display-xs-none',
                    )}>
                    <Text
                        isHtml
                        className={cls(
                            'font-c200-regular text-tertiary display-xs-block',
                            styles.terms,
                        )}>
                        {translations?.terms}
                    </Text>
                </div>
            )}
            <div
                className={cls(
                    styles.buttonWrapper,
                    showSuccess && 'display-xs-none',
                )}>
                <Button
                    component="button"
                    tabIndex={-1}
                    variant="secondary"
                    size="text"
                    disabled={status === 'share-loading'}
                    onClick={submit}
                    fullWidth>
                    {status === 'share-loading'
                        ? translations?.uploading
                        : status === 'share-error'
                        ? translations?.retry
                        : translations?.submit}
                </Button>
                {status === 'share-error' && (
                    <Text
                        className={cls(
                            'font-c200-regular',
                            'mt-tiny',
                            'display-xs-block',
                            'text-error',
                        )}>
                        {translations?.errorMessage}
                    </Text>
                )}
            </div>
            <Text
                className={cls(
                    styles.successWrapper,
                    'font-c100-regular',
                    showSuccess ? 'display-xs-block' : 'display-xs-none',
                )}>
                {translations?.successMessage}
            </Text>
        </Collapse>
    );
}
