import React from 'react';

import { Container, Row, Col } from '@soluto-design/react';
import SectionTheme, { SectionThemeProps } from '../../molecules/SectionTheme';
import Title, { TitleProps } from '../../molecules/Title';
import cls from '@soluto-design/styles/cls';

export interface TitleSectionProps extends TitleProps {
    changeThemeTo?: SectionThemeProps['changeThemeTo'];
    theme?: SectionThemeProps['theme'];
}

export default function TitleSection({
    size = 'large',
    changeThemeTo,
    theme,
    ...props
}: TitleSectionProps) {
    return (
        <SectionTheme
            {...(!!changeThemeTo && { changeThemeTo })}
            {...(!!theme && { theme })}
            className={cls('pt-xxxl')}>
            <Container>
                <Row>
                    <Col
                        col={
                            size === 'large'
                                ? { md: 7, lg: 9 }
                                : size === 'medium'
                                ? { md: 7, lg: 9, hd: 8 }
                                : size === 'small'
                                ? { md: 6, lg: 7 }
                                : {}
                        }>
                        <Title size={size} {...props} />
                    </Col>
                </Row>
            </Container>
        </SectionTheme>
    );
}
