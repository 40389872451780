const URL = process.env.GATSBY_URL
    ? process.env.GATSBY_URL
    : process.env.GATSBY_VERCEL_URL
        ? `https://${process.env.GATSBY_VERCEL_URL}/`
        : `https://solublestudio.com/`;

const IS_PREVIEW =
    process.env.GATSBY_VERCEL_ENV &&
    process.env.GATSBY_VERCEL_ENV !== 'production';

const IS_PREVIEW_SERVER =
    process.env.VERCEL_ENV && process.env.VERCEL_ENV !== 'production';

const BLOG_MAIN_SLUG = 'solublabla';

const DEFAULT_TAG = {
    es: { id: `/es/${BLOG_MAIN_SLUG}/`, slug: `/es/${BLOG_MAIN_SLUG}/` },
    en: { id: `/en/${BLOG_MAIN_SLUG}/`, slug: `/en/${BLOG_MAIN_SLUG}/` },
};

const BLOG_ITEMS_PER_PAGE = 9;

module.exports = {
    URL,
    IS_PREVIEW,
    IS_PREVIEW_SERVER,
    DEFAULT_TAG,
    BLOG_MAIN_SLUG,
    BLOG_ITEMS_PER_PAGE,
};
