import { URL } from '../../constants';

const BLACKBOARD_KEY = '@soluble/bb';

export const COLORS = [
    {
        name: 'Amarillo',
        hex: '#FFD337',
    },
    {
        name: 'Verde',
        hex: '#19E275',
    },
    {
        name: 'Azul',
        hex: '#3CDCFF',
    },
    {
        name: 'Rojo',
        hex: '#FF2525',
    },
    {
        name: 'Lila',
        hex: '#B455FF',
    },
];

export const THICKNESS = [2, 4, 8, 12, 16];

function getRandomItem(array) {
    return array?.length
        ? array[Math.floor(Math.random() * array.length)]
        : null;
}

function getStateWithIds(nodeList = [], elementType = 'input') {
    if (nodeList?.length) {
        const state = [...nodeList].reduce((currentState, node) => {
            if (node?.id) {
                if (elementType === 'input') {
                    return Object.assign(currentState, {
                        [node?.id]: {
                            element: elementType,
                            type: node?.type,
                            value:
                                ['checkbox', 'radio'].indexOf(node?.type) !== -1
                                    ? !!node?.checked
                                    : node?.value,
                        },
                    });
                }

                if (elementType === 'video') {
                    return Object.assign(currentState, {
                        [node?.id]: {
                            element: elementType,
                            currentTime: node?.currentTime,
                        },
                    });
                }
            }

            return currentState;
        }, {});

        return state;
    }

    return {};
}

export function getScreenState() {
    let scroll =
        window.pageYOffset || window.document.documentElement.scrollTop;
    if (!scroll && document.body.style.top) {
        scroll = Math.abs(parseInt(document.body.style.top.replace('px', '')));
    }

    // AVOID misunalignment if scrollbar is hidden
    let paddingRight = 0;

    if (window.getComputedStyle) {
        const bodyStyle = window.getComputedStyle(window.document.body, null);
        if (bodyStyle?.paddingRight) {
            paddingRight =
                parseInt(bodyStyle.paddingRight.replace('px', '')) || 0;
        }
    }

    return {
        width:
            (window.document.documentElement.clientWidth || window.innerWidth) -
            paddingRight,
        height:
            window.document.documentElement.clientHeight || window.innerHeight,
        fullHeight: window.document.body.offsetHeight,
        scroll,
        path: window.location.pathname,
    };
}

export function getUserState() {
    const wrapper = document.getElementById('gatsby-focus-wrapper');

    if (wrapper) {
        const videoNodeList = wrapper.querySelectorAll('body video') ?? [];
        const iframeNodeList = wrapper.querySelectorAll('body iframe') ?? [];
        const inputsNodeList =
            wrapper.querySelectorAll([
                'input[type="text"]',
                'input[type="email"]',
                'input[type="number"]',
                'input[type="checkbox"]:not(#accept-share-terms)',
            ]) ?? [];
        const selectsNodeList = wrapper.querySelectorAll('select') ?? [];

        const stateApp = {
            ...getStateWithIds(videoNodeList, 'video'),
            ...getStateWithIds(iframeNodeList, 'iframe'),
            ...getStateWithIds(inputsNodeList, 'input'),
            ...getStateWithIds(selectsNodeList, 'select'),
        };

        return stateApp;
    }

    return {};
}

export function getIframeUrl(state) {
    return `${URL}${state.__screen.path.replace(
        /^\//,
        '',
    )}?state=${encodeURIComponent(JSON.stringify(state))}`;
}

export function getItemLocalStorage() {
    if (typeof window === 'undefined') {
        return {};
    }

    const data = window.localStorage.getItem(BLACKBOARD_KEY);
    return {
        c: getRandomItem(COLORS),
        t: getRandomItem(THICKNESS),
        ...(data !== null ? JSON.parse(data) : {}),
    };
}

export function setItemLocalStorage(data = {}) {
    if (typeof window !== 'undefined') {
        window.localStorage.setItem(BLACKBOARD_KEY, JSON.stringify(data));
    }
}

function pauseStuff() {
    const videos = window.document.querySelectorAll('video');
    [...videos].forEach((video) => {
        video.pause();
    });

    const animations = window.document.querySelectorAll('[data-animation]');
    [...animations].forEach((animation) => {
        animation.style.animation = 'none';
        animation.style.transition = 'none';
    });

    if (window?.cookiehub?.destroy) {
        window.cookiehub.destroy();
    }
}

export function setScreenState(state) {
    if (typeof window === 'undefined' || !state) {
        return;
    }

    window.document.body.dataset.screenshot = true;

    // TODO: Hacer que espere los videos
    // TODO: Desactivar tracking
    // TODO: Cuando se quita el header, se quita el espacio disponible y se lía
    // TODO: Al loro con el límite de 429 de apiFlash

    for (const id in state) {
        switch (id) {
            case '__screen':
                let times = 10;
                let interval = window.setInterval(() => {
                    if (window.document.body.style.position === 'fixed') {
                        window.document.body.style.top = `-${state.__screen.scroll}px`;
                    } else {
                        window.document.documentElement.scrollTop =
                            state.__screen.scroll;
                    }

                    pauseStuff();

                    times--;
                    if (!times) {
                        window.clearInterval(interval);
                    }
                }, 100);
                break;
            case '__svg':
                const svg = window.document.createElement('div');

                svg.style.position = 'absolute';
                svg.style.top = 0;
                svg.style.left = 0;
                svg.style.width = '100%';
                svg.style.height = '100%';
                svg.style.zIndex = 9999;
                svg.style.backgroundImage = `url(${state.__svg.url})`;
                svg.style.backgroundPosition = 'top';
                svg.style.backgroundRepeat = 'no-repeat';
                svg.style.backgroundSize = '100% auto';
                window.document.body.appendChild(svg);

                const style = window.document.createElement('style');

                style.innerHTML = `#gatsby-focus-wrapper { position: relative !important; }`;
                window.document.body.appendChild(style);

                break;
            default:
                if (state[id]) {
                    const element = state[id];

                    const currentElement = document.getElementById(id) ?? null;

                    if (currentElement && element?.element === 'input') {
                        if (
                            element?.type === 'checkbox' ||
                            element?.type === 'radio'
                        ) {
                            currentElement.checked = element.value;
                        } else {
                            currentElement.value = element.value;
                        }

                        // TODO: Faltan los selects
                    }

                    if (element?.element === 'video') {
                        currentElement.currentTime = element.currentTime;
                    }
                }
        }
    }
}
