import React from 'react';
import { TextFieldSimple, TextFieldSimpleProps } from '@soluto-design/react';

import cls from '@soluto-design/styles/cls';

import style from './styles.module.scss';

export interface TextFieldFloatProps
    extends Omit<
        TextFieldSimpleProps,
        'rightIcon' | 'readOnly' | 'type' | 'rightIconButtonProps' | 'id'
    > {}

const TextFieldFloat = React.forwardRef<HTMLInputElement, TextFieldFloatProps>(
    function TextFieldFloat(
        {
            help,
            error,
            success,
            value,
            placeholder,
            label,
            ...props
        }: TextFieldFloatProps,
        ref,
    ) {
        const messageInlineProps = {
            size: 'filled',
            className: style.message,
        };

        return (
            <TextFieldSimple
                ref={ref}
                size="filled"
                variant="filled"
                classes={{
                    wrapper: style.root,
                    input: style.input,
                    inputWrapper: style.inputWrapper,
                    label: cls(style.label),
                }}
                label={label}
                placeholder={label}
                help={
                    help
                        ? {
                              ...messageInlineProps,
                              children: help,
                          }
                        : null
                }
                error={
                    error
                        ? {
                              ...messageInlineProps,
                              size: 'filled',
                              children: error,
                          }
                        : null
                }
                success={
                    success
                        ? {
                              ...messageInlineProps,
                              size: 'filled',
                              children: success,
                          }
                        : null
                }
                {...props}
            />
        );
    },
);

export default TextFieldFloat;
