import React, { useRef, useEffect, CSSProperties } from 'react';

import cls from '@soluto-design/styles/cls';
import { Icon } from '@soluto-design/react';
import { Color } from '.';

import styles from './styles.module.scss';

export interface CursorProps {
    color: Color;
    thickness: number;
    showThickness: boolean;
}

export default function Cursor({
    color,
    thickness,
    showThickness,
}: CursorProps) {
    const cursor = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (typeof window === 'undefined' || !cursor?.current) {
            return;
        }

        function onMouseMove(e: MouseEvent) {
            cursor?.current?.style.setProperty('--bb-x', `${e.x}px`);
            cursor?.current?.style.setProperty('--bb-y', `${e.y}px`);
        }

        window.addEventListener('mousemove', onMouseMove);

        return () => {
            window.removeEventListener('mousemove', onMouseMove);
        };
    }, [cursor]);

    return (
        <div
            ref={cursor}
            data-screenshot-hide
            style={
                {
                    color: color?.hex,
                    '--bb-thickness': `${thickness}px`,
                } as CSSProperties
            }
            className={cls(
                styles.cursor,
                showThickness && styles['show-thickness'],
            )}>
            <Icon icon="pencil-2" />
        </div>
    );
}
