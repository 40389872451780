import React from 'react';
import cls from '@soluto-design/styles/cls';
import { Container } from '@soluto-design/react';
import BlockText, { BlockTextProps } from '../../molecules/BlockText';
import styles from './styles.module.scss';

export interface BlockTextSectionProps extends BlockTextProps {
    pb?: string | null;
    pt?: string | null;
}

export default function BlockTextSection({
    pb = 'extra',
    pt = 'xs',
    ...props
}: BlockTextSectionProps) {
    return (
        <section className={cls(pt && `pt-${pt}`, pb && `pb-${pb}`)}>
            <Container>
                <BlockText
                    {...props}
                    className={cls(styles.text, !pt && styles.withoutMargin)}
                />
            </Container>
        </section>
    );
}
