import React, { HTMLAttributes } from 'react';
import cls from '@soluto-design/styles/cls';

import Picture, { PictureProps } from '@soluto-design/picture';

import styles from './styles.module.scss';

export interface HeroSolublablaSectionProps
    extends Omit<HTMLAttributes<HTMLElement>, 'title'> {
    image?: PictureProps;
    rounded?: 'full' | 'none' | 'bottom';
}

export default function HeroPost({ image, className, rounded = 'full' }) {
    return (
        <section className={cls(styles.root, styles?.[rounded], className)}>
            {!!image && (
                <Picture
                    layout="fill"
                    src={image?.src ?? image}
                    alt={image?.alt}
                />
            )}
        </section>
    );
}
