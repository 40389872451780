import React from 'react';

import cls from '@soluto-design/styles/cls';

import styles from './styles.module.scss';

interface DividerProps {
    className?: string;
}
const Divider = ({ className }: DividerProps) => {
    return <div className={cls(styles.divider, className)} />;
};

export default Divider;
