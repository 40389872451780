import React from 'react';

import { Text, Title } from '@soluto-design/react';
import cls from '@soluto-design/styles/cls';
import Link from '@soluto-design/link';
import CardBase, { CardBaseProps } from '../CardBase';

import styles from './styles.module.scss';

export interface CardMediaPostProps extends CardBaseProps {
    title: string;
    subtitle?: string;
}

export default function CardMediaPost({
    image,
    video,
    title,
    subtitle,
    tags,
    href,
}: CardMediaPostProps) {
    return (
        <article>
            <CardBase
                href={href}
                tags={tags}
                image={image}
                video={video}
                hasCardPostHeight
            />
            <Link
                href={href}
                className={cls('no-decoration display-xs-inline-block')}>
                <Title
                    component="h4"
                    className={cls('font-c100-regular pr-xg', styles.title)}>
                    {title}
                </Title>
            </Link>
            {!!subtitle && (
                <Text
                    component="p"
                    className={cls('font-c200-regular pr-xg', styles.subtitle)}>
                    {subtitle}
                </Text>
            )}
        </article>
    );
}
