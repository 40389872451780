import React, { useCallback, useState } from 'react';

import cls from '@soluto-design/styles/cls';

import Link, { LinkProps } from '@soluto-design/link';

import styles from './styles.module.scss';

const logoParts = [
    'S<span>-)</span>',
    'S<span>-</span>',
    'S',
    'SO',
    'SOL',
    'SOLU',
    'SOLUB',
    'SOLUBL',
    'SOLUBLE',
    'SOLUBLE',
    'SOLUBLE',
];

export default function Logo({ className, ...props }: LinkProps) {
    const [letterIndex, setLetterIndex] = useState(0);

    const stop = useCallback(() => {
        if (window.letterInterval) {
            window.clearInterval(window.letterInterval);
            window.letterInterval = null;
        }
        setLetterIndex(0);
    }, [setLetterIndex]);

    const play = useCallback(() => {
        let currentIndex = 0;
        let increase = true;

        stop();

        window.letterInterval = setInterval(() => {
            if (increase) {
                currentIndex++;
            } else {
                currentIndex--;
            }

            if (increase && currentIndex === logoParts.length - 1) {
                increase = false;
            } else if (!increase && !currentIndex) {
                stop();
            }

            setLetterIndex(currentIndex);
        }, 200);
    }, [setLetterIndex, stop]);

    return (
        <Link
            {...props}
            className={cls(
                styles.wrapper,
                'text-primary',
                'font-b400-regular',
                'd-block',
                className,
            )}
            onMouseEnter={play}
            onMouseLeave={stop}>
            <span
                dangerouslySetInnerHTML={{ __html: logoParts[letterIndex] }}
            />
        </Link>
    );
}
