import React, { useRef } from 'react';
import { Container, Title, Button } from '@soluto-design/react';
import cls from '@soluto-design/styles/cls';
import Slider, { SliderProps } from '../../tech/Slider';
import CardMediaFeatured, {
    CardMediaFeaturedProps,
} from '../../molecules/CardMedia/CardMediaFeatured';
import CardMediaText, {
    CardMediaTextProps,
} from '../../molecules/CardMedia/CardMediaText';
import styles from './styles.module.scss';

export interface CardMediaCarouselSectionProps
    extends Omit<SliderProps, 'children'> {
    cards: (CardMediaFeaturedProps | CardMediaTextProps)[];
    variant: 'featured-posts' | 'default-posts' | 'related-posts';
    title?: string;
    label?: string;
    topSpacer?: string;
    className?: string;
}

const SliderControls = ({ title, prevSlide, nextSlide, variant }) => (
    <>
        <Title component="h4" className="font-b400-regular">
            {title}
        </Title>
        <div
            className={cls(
                styles.buttons,
                variant === 'featured-posts' && styles.featuredButtons,
                'display-xs-none display-md-flex',
            )}>
            <Button
                data-theme={variant === 'featured-posts' ? 'lightgrey' : null}
                variant="secondary"
                size="icon"
                rightIcon="arrow-chevron--type-left"
                onClick={prevSlide}
            />
            <Button
                data-theme={variant === 'featured-posts' ? 'lightgrey' : null}
                variant="secondary"
                size="icon"
                rightIcon="arrow-chevron--type-right"
                onClick={nextSlide}
            />
        </div>
    </>
);

export default function CardMediaCarouselSection({
    cards,
    variant = 'default-posts',
    title,
    label,
    topSpacer,
    className,
}: CardMediaCarouselSectionProps) {
    const sliderRef = useRef(null);

    const prevSlide = () => {
        if (sliderRef.current) {
            sliderRef.current.prev();
        }
    };

    const nextSlide = () => {
        if (sliderRef.current) {
            sliderRef.current.next();
        }
    };
    return (
        <section
            className={cls(
                variant === 'default-posts' && 'mb-extra',
                variant === 'related-posts' && 'pb-huge',
                className,
                topSpacer,
            )}>
            {!!title && (
                <Container
                    className={cls('display-xs-flex justify-xs-between mb-xg')}>
                    <SliderControls
                        title={title}
                        prevSlide={prevSlide}
                        nextSlide={nextSlide}
                        variant={variant}
                    />
                </Container>
            )}
            {!!cards && (
                <Slider variant={variant} ref={sliderRef}>
                    {cards.map((card, i) =>
                        variant === 'featured-posts' ? (
                            <CardMediaFeatured
                                key={`card-${variant}-${i}`}
                                category={card.relatedTag[0].singular}
                                {...(card as CardMediaFeaturedProps)}
                            />
                        ) : (
                            <CardMediaText
                                key={`card-${variant}-${i}`}
                                text={card.title}
                                {...(card as CardMediaTextProps)}
                            />
                        ),
                    )}
                </Slider>
            )}
            {!!label && (
                <Container
                    className={cls('display-xs-flex justify-xs-between')}>
                    <SliderControls
                        title={label}
                        prevSlide={prevSlide}
                        nextSlide={nextSlide}
                        variant={variant}
                    />
                </Container>
            )}
        </section>
    );
}
