import React, { HTMLAttributes } from 'react';

import CardMediaCarouselSection from '../../organisms/CardMediaCarouselSection';
import { CardMediaFeaturedProps } from '../../molecules/CardMedia/CardMediaFeatured';
import cls from '@soluto-design/styles/cls';
import styles from './styles.module.scss';

export interface HeroBlogSectionProps
    extends Omit<HTMLAttributes<HTMLElement>, 'title'> {
    cards?: CardMediaFeaturedProps[];
    label?: string;
}

export default function HeroBlogSection({ cards, label }) {
    return (
        <section
            data-theme="black"
            className={cls(styles.root, 'pt-xxxl pb-huge')}>
            <CardMediaCarouselSection
                variant="featured-posts"
                cards={cards}
                label={label}
                className={cls('pb-sm')}
            />
        </section>
    );
}
