import { useEffect, useState, useCallback } from 'react';

const usePagination = (items: any[], itemsPerPage: number) => {
    const [skip, setSkip] = useState(itemsPerPage);
    const [itemsSliced, setItemsSliced] = useState(() => items?.slice(0, skip));

    useEffect(() => {
        setSkip(itemsPerPage);
    }, [items, itemsPerPage]);

    useEffect(() => {
        setItemsSliced(items.slice(0, skip));
    }, [skip, items, setItemsSliced]);

    const loadMore = useCallback(() => {
        setSkip(
            items?.length > skip + itemsPerPage
                ? skip + itemsPerPage
                : items?.length,
        );
    }, [setSkip, skip, items, itemsPerPage]);

    return { itemsSliced, hasMore: skip < items?.length, loadMore };
};

export default usePagination;
