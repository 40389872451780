import React, {
    useContext,
    createContext,
    useState,
    useEffect,
    useCallback,
    useRef,
    useMemo,
} from 'react';
import {
    getScreenState,
    getUserState,
    getIframeUrl,
    setItemLocalStorage,
    getItemLocalStorage,
    setScreenState,
} from './utils';
import useDisableScroll from '@solublestudio/soluble-design-system/src/hooks/useDisableScroll';
import { URL } from '../../constants';

const AppContext = createContext({});

const URL_SCREENSHOT = `${URL}api/screenshot/send`;

export const AppProvider = ({ children }) => {
    const blackboard = useRef();
    const [status, setStatus] = useState(null);
    const [screenData, setScreenData] = useState({});
    const [stateBoard, setStateBoard] = useState(getItemLocalStorage);
    const { disableScroll } = useDisableScroll();

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        const queryString = `${window.location.search}`.replace(/^\?/, '');
        const urlParams = new URLSearchParams(queryString);

        if (urlParams.has('state')) {
            disableScroll();

            const state = JSON.parse(
                decodeURIComponent(urlParams.get('state')),
            );

            setScreenState(state);
        }
    }, []);

    const submit = useCallback(() => {
        setStatus('share-loading');

        const body = {
            state: {
                ...getUserState(),
                __screen: getScreenState(),
            },
            svg: screenData?.svg,
            userId: 'unknown',
            acceptedTerms: true,
        };

        window
            .fetch(URL_SCREENSHOT, {
                method: `POST`,
                headers: {
                    'content-type': 'application/json',
                },
                body: JSON.stringify(body),
            })
            .then((res) => res.json())
            .then((res) => {
                setStatus(res?.id ? 'share-success' : 'share-error');
            })
            .catch((error) => {
                setStatus('share-error');
                console.log(error);
            });
    }, [screenData?.svg, setStatus]);

    const setProperty = useCallback(
        (key, value) => {
            stateBoard[key] = value;
            setItemLocalStorage(stateBoard);
            setStateBoard({
                ...stateBoard,
                [key]: value,
            });
        },
        [setStateBoard, stateBoard],
    );

    const setColor = useCallback(
        (color) => setProperty('c', color),
        [setProperty],
    );

    const setThickness = useCallback(
        (color) => setProperty('t', color),
        [setProperty],
    );

    const toggleDrawing = useCallback(() => {
        setStatus((status) => (status ? null : 'draw'));
    }, [setStatus]);

    const toggleChoosing = useCallback(() => {
        setStatus((status) =>
            status === 'draw-choosing' ? 'draw' : 'draw-choosing',
        );
    }, [setStatus]);

    const toggleSharing = useCallback(async () => {
        if (status === 'share') {
            setStatus('draw');

            setTimeout(() => {
                setScreenData({});
            }, 200);
        } else {
            try {
                const svg = await blackboard?.current?.exportSvg();
                const screenState = getScreenState();

                setScreenData({
                    ...screenState,
                    iframeUrl: getIframeUrl({
                        __screen: screenState,
                        ...getUserState(),
                    }),
                    svg,
                });

                setStatus('share');
            } catch (error) {}
        }
    }, [blackboard, status, setScreenData, setStatus]);

    const [isDrawing, isSharing, isChoosing] = useMemo(
        () => [
            status?.includes('draw'),
            status?.includes('share'),
            status === 'draw-choosing',
        ],
        [status],
    );

    return (
        <AppContext.Provider
            value={{
                blackboard,
                screenData,
                isDrawing,
                isSharing,
                isChoosing,
                color: stateBoard.c,
                setColor,
                thickness: stateBoard.t,
                setThickness,
                toggleDrawing,
                toggleChoosing,
                toggleSharing,
                undo: blackboard.current?.undo,
                status,
                submit,
            }}>
            {children}
        </AppContext.Provider>
    );
};

export const AppConsumer = AppContext.Consumer;

export const useAppState = () => {
    return useContext(AppContext);
};
