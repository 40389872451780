import React from 'react';
import { Title, Text, Container, Row, Col } from '@soluto-design/react';
import cls from '@soluto-design/styles/cls';
import styles from './styles.module.scss';

export interface HeroPageSectionProps {
    title: string;
    titleStyle?: 'b100';
    subtitle?: string;
}

export default function HeroPageSection({
    title,
    titleStyle = 'b000',
    subtitle,
}) {
    return (
        <section className={cls('pt-huge pb-lg background-primary')}>
            <Container>
                <Row>
                    <Col
                        col={{ md: 6, lg: 6 }}
                        offset={{ md: 1, lg: 3 }}
                        className={cls(
                            styles.col,
                            'display-xs-flex flex-xs-column',
                        )}>
                        {!!title && (
                            <Title
                                isHtml
                                component="h1"
                                className={cls(
                                    `font-${titleStyle}-regular text-primary`,
                                    styles.title,
                                )}>
                                {title}
                            </Title>
                        )}
                        {!!subtitle && (
                            <Text
                                isHtml
                                component="div"
                                className={cls(
                                    'font-b500-regular text-secondary',
                                    styles.subtitle,
                                )}>
                                {subtitle}
                            </Text>
                        )}
                    </Col>
                </Row>
            </Container>
        </section>
    );
}
