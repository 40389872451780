import React, { HTMLAttributes } from 'react';

import Link, { LinkProps } from '@soluto-design/link';
import { PictureProps } from '@soluto-design/picture';
import { Button } from '@soluto-design/react';
import cls from '@soluto-design/styles/cls';

import Tag from '../../../atoms/Tag';
import { VideoProps } from '../../Video';
import Media from '../Media';

import styles from './styles.module.scss';

export interface CardBaseProps extends HTMLAttributes<HTMLElement> {
    image?: PictureProps;
    video?: VideoProps['video'];
    secondaryImage?: PictureProps;
    secondaryVideo?: VideoProps['video'];
    tags?: string[];
    href: LinkProps['href'];
    className?: string;
    altText?: string;
    children?: React.ReactNode;
    gradient?: boolean;
    dataTheme?: 'black' | 'white';
    hasCardPostHeight?: boolean;
}

const CardBase: React.FC<CardBaseProps> = ({
    image,
    video,
    secondaryImage,
    secondaryVideo,
    tags,
    href,
    className,
    altText = '',
    children,
    gradient,
    dataTheme = 'white',
    hasCardPostHeight,
}: CardBaseProps) => {
    return (
        <div
            data-theme={dataTheme}
            className={cls(
                'display-xs-block mb-xs position-xs-relative p-xs',
                styles.imageWrapper,
                !image && styles.noImage,
                gradient && styles.gradient,
                hasCardPostHeight && styles.cardPostHeight,
                className,
            )}>
            <Link href={href}>
                <Media
                    image={image?.src ?? image}
                    video={video}
                    altText={altText}
                />
                {(secondaryImage || secondaryVideo) && (
                    <Media
                        image={secondaryImage?.src ?? secondaryImage}
                        video={secondaryVideo}
                        altText={altText}
                        className={cls(styles.secondaryMedia)}
                    />
                )}
            </Link>

            {children}
            <div
                className={cls(
                    'display-xs-flex justify-xs-between',
                    !tags && styles.btnWrapper,
                )}>
                {tags && (
                    <div>
                        {tags.map((tag, i) => (
                            <Tag
                                key={`tag-${i}`}
                                className={cls('mr-tiny mb-tiny', styles.tag)}
                                size="text"
                                variant="text">
                                {tag}
                            </Tag>
                        ))}
                    </div>
                )}
                <Button
                    className={styles.btn}
                    rightIcon="plus"
                    variant="secondary"
                    size="icon"
                    component="span"
                />
            </div>
        </div>
    );
};

export default CardBase;
