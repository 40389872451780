import React from 'react';

import { Text } from '@soluto-design/react';
import cls from '@soluto-design/styles/cls';

import styles from './styles.module.scss';

export interface CardTextSmallProps {
    title: string;
    className: string;
}

export default function CardTextSmall({
    title,
    className,
}: CardTextSmallProps) {
    return (
        <>
            {!!title && (
                <div
                    className={cls(
                        'px-md radius-small background-secondary display-xs-flex align-xs-center',
                        styles.card,
                        className,
                    )}>
                    <Text
                        component="p"
                        className={cls('font-c100-regular text-primary')}>
                        {title}
                    </Text>
                </div>
            )}
        </>
    );
}
