import React from 'react';

import { Container, Row, Col } from '@soluto-design/react';
import cls from '@soluto-design/styles/cls';
import SectionTheme, { SectionThemeProps } from '../../molecules/SectionTheme';
import Accordion from '../../molecules/Accordion';
import TitleSection from '../TitleSection';
import styles from './styles.module.scss';

export interface FaqComponentProps {
    title: string;
    body: string;
    show: boolean;
}

export interface FaqsSectionProps extends SectionThemeProps {
    title: string;
    faqs: FaqComponentProps[];
    previousSectionType?: string;
    light?: boolean;
}

export default function FaqsSection({
    title,
    faqs,
    light,
    previousSectionType,
    ...props
}: FaqsSectionProps) {
    return (
        <>
            <TitleSection
                uppercase
                tag="h2"
                title={title}
                className={cls('mb-xxl')}
                size="medium"
                {...(!!light && { theme: 'white' })}
            />
            <SectionTheme
                {...props}
                {...(!!light && { theme: 'white' })}
                className={cls(
                    'DatoCmsTextSection' === previousSectionType && styles.noPt,
                    light && 'pb-huge',
                )}>
                <Container>
                    <Row>
                        <Col col={{ md: 7, lg: 9, hd: 8 }}>
                            {faqs?.map((faq, i) => (
                                <Accordion key={i} {...faq} />
                            ))}
                        </Col>
                    </Row>
                </Container>
            </SectionTheme>
        </>
    );
}
