import React from 'react';
import { Text, Col, Row, Container, Button } from '@soluto-design/react';
import { LinkProps } from '@soluto-design/link';

import CardProject, {
    CardProjectProps,
} from '../../molecules/CardMedia/CardProject';
import cls from '@soluto-design/styles/cls';
import styles from './styles.module.scss';

interface ExtendedCardProjectProps extends CardProjectProps {
    isHidden?: boolean;
    showOnlyHd?: boolean;
}

export interface ProjectsSectionProps {
    title: string;
    cards?: ExtendedCardProjectProps[];
    cta?: LinkProps;
    onViewMore?: () => void;
    hasMoreItems?: boolean;
    viewMoreLabel?: string;
}

export default function ProjectsSection({
    title,
    cards = [],
    cta,
    onViewMore,
    hasMoreItems,
    viewMoreLabel,
}: ProjectsSectionProps) {
    return (
        <section className={cls('py-huge')}>
            <Container>
                <div
                    className={cls(
                        'display-xs-flex mb-lg',
                        styles.titleWrapper,
                    )}>
                    {title && (
                        <Text
                            component="div"
                            className="font-b500-regular text-primary">
                            {title}
                        </Text>
                    )}
                    {cta && (
                        <Button
                            {...cta}
                            component="a"
                            className={cls(
                                'display-xs-none display-md-block',
                                styles.topButton,
                            )}
                            variant="secondary"
                            size="text"
                        />
                    )}
                </div>
                <Row className={styles.row}>
                    {cards?.map((card, i) => (
                        <Col
                            key={`project-card-${i}`}
                            className={cls(
                                styles.col,
                                card.isHidden && 'display-xs-none',
                                card.showOnlyHd && styles.showOnlyHd,
                            )}
                            col={{
                                md: 4,
                                lg: 6,
                                hd: cards.length % 3 === 0 ? 4 : 6,
                            }}>
                            <CardProject {...card} />
                        </Col>
                    ))}
                </Row>
                {cta && (
                    <div className={cls('display-md-none')}>
                        <Button
                            {...cta}
                            fullWidth
                            className={cls('mt-xl', styles.bottomButton)}
                            component="a"
                            variant="secondary"
                            size="text"
                        />
                    </div>
                )}
                {!cta && hasMoreItems && (
                    <Button
                        onClick={onViewMore}
                        fullWidth
                        className={cls('mt-xl', styles.bottomButton)}
                        component="a"
                        variant="secondary"
                        size="text">
                        {viewMoreLabel}
                    </Button>
                )}
            </Container>
        </section>
    );
}
