import React, { useMemo } from 'react';

import { Button } from '@soluto-design/react';
import cls from '@soluto-design/styles/cls';
import Link, { LinkProps } from '@soluto-design/link';

import styles from './styles.module.scss';

export interface LinkInPageProps extends LinkProps {
    iconName?: string | 'none';
}

export default function LinkInPage({
    children,
    className,
    iconName,
    ...props
}: LinkInPageProps) {
    return (
        <Link
            className={cls(
                styles.root,
                'no-decoration display-xs-flex justify-xs-between align-xs-center',
                'font-b300-regular py-xxs pr-modulor',
                className,
            )}
            {...props}>
            <span
                className={styles.text}
                {...(typeof children === 'string'
                    ? { dangerouslySetInnerHTML: { __html: children } }
                    : { children })}
            />
            <Button
                variant="secondary"
                size="icon"
                component="span"
                rightIcon={iconName || 'arrow-direction--type-right'}
                className={cls(
                    'ml-xs display-md-none',
                    styles.button,
                    styles.buttonMobile,
                    iconName === 'none' && styles.buttonHidden,
                )}
            />
            <Button
                variant="secondary"
                size="icon"
                component="span"
                rightIcon={iconName || 'arrow-direction--type-right'}
                className={cls(
                    'ml-xs',
                    styles.button,
                    styles.buttonDesktop,
                    iconName === 'none' && styles.buttonHidden,
                )}
            />
        </Link>
    );
}
