import React from 'react';

import { Button } from '@soluto-design/react';
import cls from '@soluto-design/styles/cls';

import Link, { LinkProps } from '@soluto-design/link';

import styles from './styles.module.scss';

export interface HeaderItemProps extends LinkProps {
    active?: boolean;
    primary?: boolean;
}

export default function HeaderItem({
    children,
    primary,
    ...props
}: HeaderItemProps) {
    return (
        <Link
            classes={{
                root: cls(
                    styles.link,
                    `background-${primary ? 'tertiary' : 'secondary'}`,
                    'radius-medium pl-sm display-xs-flex align-xs-center justify-xs-between font-b500-regular no-decoration',
                ),
                active: cls('text-secondary'),
            }}
            partiallyActive
            {...props}>
            {children}
            {primary && (
                <Button
                    className={cls(styles.linkIcon, 'mx-xxs')}
                    component="span"
                    variant="secondary"
                    size="icon"
                    rightIcon="arrow-corner--type-right-up"
                />
            )}
        </Link>
    );
}
