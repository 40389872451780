import React, { HTMLAttributes } from 'react';

import { ButtonProps, Button } from '@soluto-design/react';
import cls from '@soluto-design/styles/cls';
import styles from './styles.module.scss';

export interface TagProps
    extends HTMLAttributes<HTMLSpanElement>,
        Pick<ButtonProps, 'leftIcon' | 'rightIcon' | 'component' | 'disabled'> {
    size?: 'small' | 'large' | 'text';
    variant?: 'fill' | 'outline' | 'text';
}

export default function Tag({ size, variant, className, ...props }: TagProps) {
    return (
        <Button
            component="span"
            size={`tag-${size}`}
            variant={`tag-${variant}`}
            className={cls(styles.root, className)}
            {...props}
        />
    );
}
