import React from 'react';
import { Text } from '@soluto-design/react';

import cls from '@soluto-design/styles/cls';

import Tag from '../../atoms/Tag';

import styles from './styles.module.scss';

const PostIntro = ({ title, tags }) => {
    return (
        <>
            <Text
                component="h1"
                className={cls('text-primary font-d100-regular', styles.title)}
                isHtml>
                {title}
            </Text>
            {tags?.length > 0 && (
                <div className={cls('display-xs-flex mt-lg', styles.tags)}>
                    {tags.map((tag, index) => (
                        <Tag
                            key={index}
                            className={styles.tag}
                            size="text"
                            variant="text">
                            {tag?.title}
                        </Tag>
                    ))}
                </div>
            )}
        </>
    );
};

export default PostIntro;
