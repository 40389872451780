import React, { Fragment } from 'react';

import cls from '@soluto-design/styles/cls';
import Link from '@soluto-design/link';
import { Text } from '@soluto-design/react';

import styles from './styles.module.scss';

export interface AlternateLanguageProps {
    language: string;
    href: string;
    active: boolean;
}

export interface LanguageSelectorProps {
    items: AlternateLanguageProps[];
    className?: string;
    textClassName?: string;
}

const LANGUAGES = {
    en: 'English',
    es: 'Español',
};

export default function LanguageSelector({
    items = [],
    className,
    textClassName = 'font-b500-regular',
}: LanguageSelectorProps) {
    return (
        <div
            className={cls(
                'display-xs-flex flex-xs-row justify-xs-start',
                className,
            )}>
            {items.map(({ href, language, active }, i) => (
                <Fragment key={`language-${i}`}>
                    {i > 0 && (
                        <Text
                            className={cls(
                                textClassName,
                                `mx-tiny text-tertiary`,
                            )}>
                            |
                        </Text>
                    )}
                    <Link
                        href={href}
                        title={LANGUAGES[language] || language}
                        className={cls(
                            textClassName,
                            'no-decoration',
                            active ? 'text-primary' : 'text-tertiary',
                            active && styles.active,
                        )}>
                        {LANGUAGES[language] || language}
                    </Link>
                </Fragment>
            ))}
        </div>
    );
}
