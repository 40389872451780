import React from 'react';

import styles from './styles.module.scss';

export interface TypeformTemplateProps {
    title: string;
    id: string;
}

export default function TypeformTemplate({ title, id }: TypeformTemplateProps) {
    return (
        <section className={styles['section']}>
            {id ? (
                <iframe
                    title={title}
                    frameBorder="0"
                    src={`https://soluble.typeform.com/to/${id}`}
                    className={styles['typeform']}
                />
            ) : null}
        </section>
    );
}
