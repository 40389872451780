import React, { HTMLAttributes } from 'react';
import Picture, { PictureProps } from '@soluto-design/picture';
import Video, { VideoProps } from '../../Video';
import defaultImage from './default-1.jpg';
import styles from './styles.module.scss';
import cls from '@soluto-design/styles/cls';

interface MediaProps extends HTMLAttributes<HTMLElement> {
    image?: PictureProps;
    video?: VideoProps['video'];
    altText?: string;
    className?: string;
}

const Media: React.FC<MediaProps> = ({
    image,
    video,
    altText = '',
    className,
}) => {
    return (
        <>
            {image ? (
                <Picture
                    layout="fill"
                    className={cls(styles.image, className)}
                    src={image}
                    alt={image?.title || altText}
                />
            ) : video ? (
                <Video video={video} className={cls(styles.video, className)} />
            ) : (
                <img
                    className={styles.defaultImage}
                    src={defaultImage}
                    alt={altText}
                />
            )}
        </>
    );
};

export default Media;
