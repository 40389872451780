import React from 'react';

import { Icon } from '@soluto-design/react';

import cls from '@soluto-design/styles/cls';

import styles from './styles.module.scss';

export interface WidgetProps {
    onClick?: () => void;
    isLoading?: boolean;
}

export default function Widget({ onClick, isLoading, text }) {
    return (
        <div className={cls(styles.root)}>
            <button
                tabIndex={-1}
                data-theme="white"
                className={cls(
                    styles['btn'],
                    'text-primary background-primary radius-round',
                    'elevation-small',
                    isLoading && 'disabled',
                )}
                onClick={onClick}>
                {isLoading ? (
                    <div className={styles.loader} />
                ) : (
                    <Icon size={24} icon="arrow-corner--type-right-up" />
                )}

                <span className={styles.tooltip}>{text}</span>
            </button>
        </div>
    );
}
