import React from 'react';

import Link from '@soluto-design/link';
import { Text, Title } from '@soluto-design/react';
import cls from '@soluto-design/styles/cls';

import CardBase, { CardBaseProps } from '../CardBase';
import styles from './styles.module.scss';

export interface CardMediaFeaturedProps extends CardBaseProps {
    category: string;
    title: string;
}

export default function CardMediaFeatured({
    image,
    video,
    category,
    title,
    href,
}: CardMediaFeaturedProps) {
    return (
        <CardBase
            href={href}
            className={cls(styles.wrapper)}
            image={image}
            video={video}
            gradient>
            <div
                className={cls(
                    'display-xs-flex flex-xs-column justify-xs-center align-xs-center',
                    styles.textWrapper,
                )}>
                <Text
                    component="div"
                    className={cls('font-h400-regular pb-tiny text-uppercase')}>
                    {category}
                </Text>
                <Link
                    href={href}
                    className={cls(
                        'no-decoration display-xs-inline-block',
                        styles.title,
                    )}>
                    <Title
                        isHtml
                        component="h4"
                        className={cls('font-d100-regular')}>
                        {title}
                    </Title>
                </Link>
            </div>
        </CardBase>
    );
}
