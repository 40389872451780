import React from 'react';

import { Row, Col, Container, Title, TitleProps } from '@soluto-design/react';
import cls from '@soluto-design/styles/cls';

import FeaturedText from '../../molecules/FeaturedText';
import SectionTheme, { SectionThemeProps } from '../../molecules/SectionTheme';
import TitleSection from '../TitleSection';

import styles from './styles.module.scss';

interface Address {
    title: string;
    tag: TitleProps['component'];
    description: string;
}

export interface AddressesSectionProps extends SectionThemeProps {
    title: string;
    addresses: Address[];
}

export default function AddressesSection({
    title,
    addresses = [],
    ...props
}: AddressesSectionProps) {
    return (
        <>
            <TitleSection
                uppercase
                tag="h2"
                title={title}
                className={cls('mb-xxl')}
                size="medium"
            />
            <SectionTheme {...props} className={cls('pb-lg')}>
                <Container>
                    <Row>
                        {addresses.map((address, i) => {
                            return (
                                <Col
                                    key={i}
                                    col={{ md: 4, lg: 5, hd: 4 }}
                                    className={cls(
                                        'mr-md',
                                        i !== 0 && styles.marginMobile,
                                    )}>
                                    <Title
                                        component="h4"
                                        className={cls(
                                            'font-b400-regular',
                                            'mb-lg',
                                        )}>
                                        {address?.title}
                                    </Title>
                                    <FeaturedText
                                        size="medium"
                                        text={address?.description}
                                    />
                                </Col>
                            );
                        })}
                    </Row>
                </Container>
            </SectionTheme>
        </>
    );
}
