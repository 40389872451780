import { useEffect, useRef, useState } from 'react';

interface Options extends IntersectionObserverInit {
    externalRef?: React.MutableRefObject<any>;
}

const useNearScreen = (options: Options, once = true) => {
    const [isNearScreen, setShow] = useState(false);
    const fromRef = useRef(null);

    const { root, rootMargin, threshold, externalRef } = options || {};

    useEffect(() => {
        let observer: any = null;

        const element = externalRef ? externalRef?.current : fromRef.current;

        const onChange = (
            entries: IntersectionObserverEntry[],
            observer: IntersectionObserver,
        ) => {
            const el = entries[0];
            if (el.isIntersecting) {
                setShow(true);
                once && observer.disconnect();
            } else {
                !once && setShow(false);
            }
        };

        if (element) {
            observer = new IntersectionObserver(onChange, {
                threshold,
                rootMargin,
                root,
            });
            observer.observe(element);

            return () => {
                observer && observer.disconnect();
            };
        }
    }, [root, rootMargin, threshold, externalRef, fromRef, once]);

    return { isNearScreen, fromRef };
};

export default useNearScreen;
