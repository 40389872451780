import { useEffect, useLayoutEffect, useState, ReactNode } from 'react';

const useEnhancedEffect =
    typeof window !== 'undefined' && process.env.NODE_ENV !== 'test'
        ? useLayoutEffect
        : useEffect;

interface NoSSRProps {
    children: ReactNode;
    defer?: boolean;
    fallback?: ReactNode;
}

const NoSSR = ({ children, defer = false, fallback = null }: NoSSRProps) => {
    const [isMounted, setMountedState] = useState(false);

    useEnhancedEffect(() => {
        if (!defer) setMountedState(true);
    }, [defer]);

    useEffect(() => {
        if (defer) setMountedState(true);
    }, [defer]);

    return isMounted ? children : fallback;
};

export default NoSSR;
