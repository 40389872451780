import React from 'react';

import { Text, Title } from '@soluto-design/react';
import cls from '@soluto-design/styles/cls';
import Link from '@soluto-design/link';
import CardBase, { CardBaseProps } from '../CardBase';

import styles from './styles.module.scss';

export interface CardProjectProps extends CardBaseProps {
    title: string;
    subtitle?: string;
}

export default function CardProject({
    image,
    video,
    secondaryVideo,
    secondaryImage,
    title,
    subtitle,
    tags,
    href,
}: CardProjectProps) {
    return (
        <article>
            <CardBase
                href={href}
                className={cls(styles.wrapper)}
                tags={tags}
                dataTheme="white"
                image={image}
                video={video}
                secondaryVideo={secondaryVideo}
                secondaryImage={secondaryImage}
            />
            <div className={cls(styles.info)}>
                <Link
                    href={href}
                    className={cls('no-decoration display-xs-inline-block')}>
                    <Title
                        component="h4"
                        className={cls('font-c100-regular text-primary')}>
                        {title}
                    </Title>
                </Link>
                {!!subtitle && (
                    <Text
                        component="p"
                        className={cls(
                            'font-c200-regular text-tertiary',
                            styles.subtitle,
                        )}>
                        {subtitle}
                    </Text>
                )}
            </div>
        </article>
    );
}
