exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-articulos-js": () => import("./../../../src/templates/articulos.js" /* webpackChunkName: "component---src-templates-articulos-js" */),
  "component---src-templates-blabla-page-js": () => import("./../../../src/templates/blabla-page.js" /* webpackChunkName: "component---src-templates-blabla-page-js" */),
  "component---src-templates-blabla-tag-js": () => import("./../../../src/templates/blabla-tag.js" /* webpackChunkName: "component---src-templates-blabla-tag-js" */),
  "component---src-templates-case-study-js": () => import("./../../../src/templates/case-study.js" /* webpackChunkName: "component---src-templates-case-study-js" */),
  "component---src-templates-landing-page-js": () => import("./../../../src/templates/landing-page.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-mailchimp-js": () => import("./../../../src/templates/mailchimp.js" /* webpackChunkName: "component---src-templates-mailchimp-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "slice---src-components-footer-index-tsx": () => import("./../../../src/components/Footer/index.tsx" /* webpackChunkName: "slice---src-components-footer-index-tsx" */),
  "slice---src-components-header-index-tsx": () => import("./../../../src/components/Header/index.tsx" /* webpackChunkName: "slice---src-components-header-index-tsx" */)
}

