import React, {
    useState,
    useRef,
    useEffect,
    useMemo,
    useCallback,
} from 'react';
import {
    Title,
    Col,
    Row,
    useIsBreakpointDown,
    Container,
} from '@soluto-design/react';
import cls from '@soluto-design/styles/cls';
import TextSection from '../TextSection';
import styles from './styles.module.scss';
import SectionTheme, { SectionThemeProps } from '../../molecules/SectionTheme';
import FeaturedText from '../../molecules/FeaturedText';

export interface HowBlocks {
    title: string;
    slug: string;
    description: string;
}

export interface HowSectionProps extends SectionThemeProps {
    description?: string;
    blocks: HowBlocks[];
    previousSectionType?: string;
}

export default function HowSection({
    description,
    blocks,
    previousSectionType,
    ...props
}: HowSectionProps) {
    let isMobile: any = useIsBreakpointDown('md');
    const bodyRef = useRef();

    const [currentActive, setCurrentActive] = useState(0);

    useEffect(() => {
        if (!bodyRef.current || typeof window === 'undefined' || isMobile) {
            return;
        }

        let items = [
            ...bodyRef.current.querySelectorAll('[data-body]'),
        ].reverse();

        const onScroll = () => {
            let itemKey = items
                .map((item) => item.getBoundingClientRect().top)
                .findIndex((top) => top < 0);

            setCurrentActive(itemKey > -1 ? items.length - itemKey - 1 : 0);
        };

        setTimeout(onScroll, 1000);
        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, [bodyRef.current, setCurrentActive, isMobile]);

    const scrollTo = useCallback(
        (slug) => {
            const items = [...bodyRef.current.querySelectorAll(`[data-slug]`)];

            const itemIndex = items.findIndex(
                (item) => item.dataset.slug === slug,
            );

            if (itemIndex > -1) {
                const position =
                    items[itemIndex].getBoundingClientRect().top +
                    window.document.documentElement.scrollTop +
                    2;
                window.scrollTo(0, position);

                setTimeout(() => {
                    setCurrentActive(itemIndex);
                }, 0);
            }
        },
        [bodyRef.current, setCurrentActive],
    );

    const accordionItems = useMemo(() => {
        let existingSlugs: any = {};
        return blocks.map((b, i) => {
            let item = {
                ...b,
                body: <FeaturedText size="small" text={b.description} />,
                showDesktopTitle: !existingSlugs[b.slug],
            };

            existingSlugs[b.slug] = 1;

            return item;
        });
    }, [blocks]);

    return (
        <>
            {description && (
                <TextSection
                    size="large"
                    text={description}
                    sectionClassName={cls(styles.description)}
                />
            )}
            <SectionTheme
                {...props}
                className={cls(
                    styles.section,
                    'pb-huge',
                    !description && 'pt-huge',
                )}>
                <Container>
                    <div className={cls('display-lg-none')}>
                        {accordionItems.map((item, i) => (
                            <div key={`item-${i}`} className={styles.item}>
                                <Title
                                    component="h4"
                                    className="font-b400-regular mb-md">
                                    {item.title}
                                </Title>
                                {item.body}
                            </div>
                        ))}
                    </div>
                    <div className={cls('display-xs-none display-lg-flex')}>
                        <Row>
                            <Col col={{ md: 3, lg: 5 }}>
                                <div className={cls(styles.titles)}>
                                    {accordionItems
                                        .filter((item) => item.showDesktopTitle)
                                        .map((item, i) => (
                                            <button
                                                key={item.slug}
                                                className={cls(
                                                    'display-xs-block font-b400-regular',
                                                    styles.link,
                                                )}
                                                aria-expanded={
                                                    item.slug ===
                                                    accordionItems[
                                                        currentActive
                                                    ].slug
                                                }
                                                onClick={() => {
                                                    scrollTo(item.slug);
                                                }}>
                                                {item.title}
                                            </button>
                                        ))}
                                </div>
                            </Col>
                            <Col ref={bodyRef} col={{ md: 6, lg: 7 }}>
                                {accordionItems.map((item, i) => (
                                    <div
                                        key={i}
                                        data-body
                                        data-slug={item.slug}
                                        data-active={currentActive === i}
                                        className={cls(
                                            styles['desktop-body'],
                                            'pb-lg',
                                        )}>
                                        {item.body}
                                    </div>
                                ))}
                            </Col>
                        </Row>
                    </div>
                </Container>
            </SectionTheme>
        </>
    );
}
