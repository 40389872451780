import React, { useMemo, HTMLAttributes } from 'react';

import { Icon } from '@soluto-design/react';
import Link from '@soluto-design/link';
import cls from '@soluto-design/styles/cls';

import styles from './styles.module.scss';

export interface BandTextProps extends HTMLAttributes<HTMLElement> {
    separator?: string;
    href?: string;
    kind?: 'text-large' | 'text-small';
}

export default function BandText({
    separator,
    href,
    className,
    kind = 'text-large',
    children,
    ...props
}: BandTextProps) {
    const items = useMemo(
        () => Array.from({ length: 5 }).fill(children),
        [children],
    );

    const separatorElement = useMemo(() => {
        if (!separator) return null;

        return separator.startsWith('icon:') ? (
            <Icon
                className={styles.separator}
                icon={separator.replace('icon:', '')}
                size={40}
            />
        ) : (
            <img className={styles.separator} src={separator} alt="" />
        );
    }, [separator]);

    return (
        <div
            {...props}
            className={cls(
                styles.root,
                'display-xs-flex align-xs-center pt-xs pb-xxs',
                styles[kind],
                kind === 'text-large' && 'font-h200-regular',
                kind === 'text-small' && 'font-h300-regular',
                className,
            )}>
            {items.map((item, i) => {
                return href ? (
                    <Link
                        key={`band-item-${i}`}
                        data-animation
                        className={cls(
                            'display-xs-flex align-xs-center no-decoration',
                            styles.item,
                        )}
                        href={href}>
                        {item}
                        {separatorElement}
                    </Link>
                ) : (
                    <span
                        className={cls(
                            'display-xs-flex align-xs-center',
                            styles.item,
                        )}
                        data-animation
                        key={`band-item-${i}`}>
                        {item as string}
                        {separatorElement}
                    </span>
                );
            })}
        </div>
    );
}
