import React, { HTMLAttributes } from 'react';
import { Title, Container } from '@soluto-design/react';
import BandTag, { BandTagProps } from '../../molecules/BandTag';

export interface BandTagSectionProps extends HTMLAttributes<HTMLElement> {
    title?: string;
    bands: BandTagProps[];
}

export default function BandTagSection({
    title,
    bands,
    ...props
}: BandTagSectionProps) {
    return (
        <section {...props}>
            {!!title && (
                <Container>
                    <Title component="p" className="font-b400-regular mb-xg">
                        {title}
                    </Title>
                </Container>
            )}
            {bands.map((band, i) => (
                <BandTag key={`band-${i}`} {...band} />
            ))}
        </section>
    );
}
