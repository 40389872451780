import React from 'react';
import { Text, Container, Row, Col } from '@soluto-design/react';
import cls from '@soluto-design/styles/cls';
import Picture, { PictureProps } from '@soluto-design/picture';

import styles from './styles.module.scss';

export interface PostMediaSectionProps {
    postDescription?: string;
    images?: PictureProps[];
    squareImages?: PictureProps[];
}

export default function PostMediaSection({
    postDescription,
    images = [],
    squareImages = [],
}: PostMediaSectionProps) {
    const renderedImages = squareImages.length === 2 ? squareImages : images;

    return (
        <section className={cls('py-huge background-primary')}>
            <Container>
                {renderedImages.length > 0 && (
                    <Row className="display-xs-flex justify-xs-center">
                        {renderedImages.map((image, index) => (
                            <>
                                {image.gatsbyImageData && (
                                    <Col
                                        key={index}
                                        col={{
                                            md:
                                                renderedImages.length > 1
                                                    ? 3
                                                    : 6,
                                            lg:
                                                renderedImages.length > 1
                                                    ? 5
                                                    : 10,
                                        }}>
                                        <div
                                            className={cls(
                                                'position-xs-relative radius-small',
                                                styles.imageWrapper,
                                                renderedImages.length > 1 &&
                                                    styles.doubleImage,
                                                renderedImages.length > 1 &&
                                                    index === 1 &&
                                                    'display-xs-none display-md-block',
                                            )}>
                                            <Picture
                                                layout="fill"
                                                src={image}
                                                alt={image?.alt || ''}
                                            />
                                        </div>
                                    </Col>
                                )}
                            </>
                        ))}
                    </Row>
                )}
                {!!postDescription && (
                    <Row>
                        <Col col={{ md: 6, lg: 6 }} offset={{ md: 1, lg: 3 }}>
                            <Text
                                isHtml
                                component="div"
                                className={cls(
                                    'font-b400-regular postDescription-primary pt-huge',
                                    styles.text,
                                )}>
                                {postDescription}
                            </Text>
                        </Col>
                    </Row>
                )}
            </Container>
        </section>
    );
}
