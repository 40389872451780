import React, { useState, useCallback, useEffect, useMemo } from 'react';

import {
    Header as HeaderDS,
    Icon,
    HeaderProps,
    Container,
    useIsBreakpointUp,
    Text,
    Button,
} from '@soluto-design/react';
import cls from '@soluto-design/styles/cls';

import { navigate } from '@soluto-design/link';

import { Collapse } from 'react-collapse';

import HeaderItem, { HeaderItemProps } from './HeaderItem';
import Logo from '../../molecules/Logo';
import LanguageSelector, {
    AlternateLanguageProps,
} from '../../molecules/LanguageSelector';
import Tools from '../Blackboard/Tools';
import NoSSR from '../../tech/NoSSR';
import useDisableScroll from '../../../hooks/useDisableScroll';
import ShareScreenshotComponent from '../ShareScreenshotComponent';

import styles from './styles.module.scss';

export interface HeadProps extends HeaderProps {
    nav: HeaderItemProps[];
    language: string;
    hideDrawingTool?: boolean;
    iconLinkHref?: string;
    toolsProps?: any;
    shareProps?: any;
    alternateLanguages?: AlternateLanguageProps[];
    copyright?: string;
}

export default function Header({
    nav,
    language,
    hideDrawingTool,
    iconLinkHref,
    toolsProps,
    shareProps,
    alternateLanguages = [],
    copyright,
}) {
    const [menuOpen, setMenuOpen] = useState(false);
    const isDesktop = useIsBreakpointUp('lg');
    const { disableScroll, enableScroll } = useDisableScroll();
    const logoHref = useMemo(
        () => iconLinkHref || `/${language}/`,
        [iconLinkHref, language],
    );

    const toggle = useCallback(() => {
        if (menuOpen) {
            window.document.body.dataset.forceMenuVisible = 'true';
            let listenerMax = 2;
            let listener: any = null;

            listener = () => {
                listenerMax--;

                if (!listenerMax) {
                    delete window.document.body.dataset.forceMenuVisible;
                    window.removeEventListener('scroll', listener);
                }
            };

            window.addEventListener('scroll', listener);
        }

        setMenuOpen(!menuOpen);
    }, [setMenuOpen, menuOpen]);

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        if (menuOpen) {
            disableScroll();
        } else {
            enableScroll();
        }

        window.document.body.dataset.menuOpen = menuOpen ? 'true' : 'false';
    }, [menuOpen, disableScroll, enableScroll]);

    useEffect(() => {
        if (isDesktop && menuOpen) {
            toggle();
        }
    }, [toggle, isDesktop, menuOpen]);

    const onClickHeaderItem = useCallback(
        (e) => {
            e.preventDefault();
            window.document.body.dataset.blur = 'true';
            setMenuOpen(false);

            const href = e.target.href || logoHref;

            setTimeout(() => {
                navigate(href);
            }, 250);
        },
        [setMenuOpen, logoHref],
    );

    return (
        <>
            <div data-theme="white" data-screenshot-hide>
                <Collapse
                    theme={{
                        collapse: cls(
                            'display-lg-none',
                            styles.collapse,
                            menuOpen && styles.collapseOpen,
                        ),
                        content: cls(
                            styles.collapseBody,
                            'display-xs-flex flex-xs-column justify-xs-between',
                        ),
                    }}
                    isOpened={menuOpen}>
                    <Container>
                        {nav.map((item, i) => (
                            <HeaderItem
                                key={`main-${i}`}
                                onClick={onClickHeaderItem}
                                {...item}
                            />
                        ))}
                    </Container>
                    <Container className="display-xs-flex justify-xs-between">
                        <LanguageSelector
                            className=""
                            textClassName="font-c200-regular"
                            items={alternateLanguages}
                        />
                        <Text className="text-tertiary font-c200-regular">
                            {copyright}
                        </Text>
                    </Container>
                </Collapse>
                <HeaderDS
                    sticky
                    classes={{
                        root: cls(styles.root, menuOpen && styles.rootOpen),
                        children: styles.children,
                        icon: 'display-xs-block',
                    }}
                    iconLink={{
                        title: 'Soluble',
                        href: logoHref,
                        'aria-label': 'Soluble',
                        className: cls(styles.logo, 'display-lg-none'),
                        ...(menuOpen && {
                            onClick: onClickHeaderItem,
                        }),
                    }}>
                    <button
                        onClick={toggle}
                        aria-label="Menu"
                        className={cls(
                            styles.menuBtn,
                            'text-primary display-lg-none',
                        )}>
                        <Icon size={24} icon={menuOpen ? 'close' : 'menu'} />
                    </button>
                    <nav
                        className={cls(
                            styles.nav,
                            'display-xs-none display-lg-flex align-xs-center',
                        )}>
                        {nav.map(({ primary, ...item }, i) => (
                            <Button
                                key={`main-desktop-${i}`}
                                component="a"
                                variant={primary ? 'primary' : 'ghost'}
                                size="text"
                                partiallyActive
                                classes={{
                                    root: cls(
                                        styles.navLink,
                                        item.primary && styles.primary,
                                    ),
                                    active: styles.active,
                                }}
                                {...item}
                            />
                        ))}
                    </nav>
                </HeaderDS>
                {!hideDrawingTool && (
                    <NoSSR>
                        <Tools {...toolsProps} />
                        <ShareScreenshotComponent {...shareProps} />
                    </NoSSR>
                )}
            </div>
            <Logo
                href={iconLinkHref || `/${language}/`}
                className={cls(
                    styles.logoDesktop,
                    'display-xs-none display-lg-inline-block',
                )}
            />
        </>
    );
}
