import React from 'react';

import { Col, Row, Button, Container } from '@soluto-design/react';
import cls from '@soluto-design/styles/cls';

import CardMediaPost, {
    CardMediaPostProps,
} from '../../molecules/CardMedia/CardMediaPost';
import SectionTheme from '../../molecules/SectionTheme';
import usePagination from '../../../hooks/usePagination';

export interface BlogListSectionProps {
    articles: CardMediaPostProps[];
    pagination?: {
        nextUrl?: string;
        previousUrl?: string;
    };
    translations?: {
        [key: string]: string;
    };
    itemsPerPage?: number;
    hasMoreHref?: string;
    renderHidden?: boolean;
}

export default function BlogListSection({
    articles = [],
    pagination = {},
    translations = {},
    itemsPerPage = 9,
    hasMoreHref,
    renderHidden = false,
}: BlogListSectionProps) {
    const {
        itemsSliced: paginatedArticles,
        hasMore,
        loadMore,
    } = usePagination(articles, itemsPerPage);

    return (
        <SectionTheme className={cls('pt-xxl pb-huge')}>
            <Container>
                <Row>
                    {articles?.map((article, i) =>
                        renderHidden || i < paginatedArticles.length ? (
                            <Col
                                col={{ md: 4, lg: 4 }}
                                className={cls(
                                    'mb-xxl',
                                    renderHidden &&
                                        i >= paginatedArticles.length
                                        ? 'display-xs-none'
                                        : null,
                                )}
                                key={`article-${article?.title}-${i}`}>
                                <CardMediaPost {...article} />
                            </Col>
                        ) : null,
                    )}
                    {hasMore && (
                        <Col className="display-xs-flex justify-xs-center">
                            <Button
                                fullWidth
                                {...(hasMoreHref
                                    ? { href: hasMoreHref }
                                    : { component: 'button' })}
                                variant="secondary"
                                size="text"
                                onClick={(e) => {
                                    e.preventDefault();
                                    loadMore();
                                }}>
                                {translations['solublabla.loadmore.text'] ??
                                    'Load More'}
                            </Button>
                        </Col>
                    )}
                </Row>
                {(pagination?.previousUrl || pagination?.nextUrl) && (
                    <div className={cls('display-xs-flex justify-xs-between')}>
                        {pagination?.previousUrl ? (
                            <Button
                                href={pagination?.previousUrl}
                                component="a"
                                variant="secondary"
                                leftIcon="arrow-corner--type-left-down"
                                size="text">
                                {translations['blog.pagination.previous']}
                            </Button>
                        ) : (
                            <div />
                        )}
                        {pagination?.nextUrl ? (
                            <Button
                                href={pagination?.nextUrl}
                                variant="secondary"
                                rightIcon="arrow-corner--type-right-up"
                                size="text"
                                component="a">
                                {translations['blog.pagination.next']}
                            </Button>
                        ) : (
                            <div />
                        )}
                    </div>
                )}
            </Container>
        </SectionTheme>
    );
}
