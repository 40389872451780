import React, { HTMLAttributes } from 'react';

import { Text, Button } from '@soluto-design/react';
import cls from '@soluto-design/styles/cls';
import { LinkProps } from '@soluto-design/link';
import Picture, { PictureProps } from '@soluto-design/picture';

import styles from './styles.module.scss';

export interface CardQuoteProps extends HTMLAttributes<HTMLElement> {
    image?: PictureProps;
    quote: string;
    button?: LinkProps;
    name: string;
    role?: string;
}

export default function CardQuote({
    image,
    quote,
    button,
    name,
    role,
    className,
    ...props
}: CardQuoteProps) {
    return (
        <div
            data-theme="white"
            className={cls(
                className,
                'p-sm display-xs-flex flex-xs-column justify-xs-between radius-medium',
                styles.root,
            )}
            {...props}>
            <div className={cls('mb-xxl')}>
                <div
                    className={cls(
                        'position-xs-relative mb-sm',
                        styles.imageWrapper,
                    )}>
                    {!!image && (
                        <Picture layout="fill" src={image} alt={name || ''} />
                    )}
                </div>
                <Text
                    component="p"
                    className={cls('font-b400-regular', styles.quote)}>
                    {quote}
                </Text>
            </div>
            <div>
                {!!button && (
                    <Button
                        {...button}
                        rightIcon="arrow-corner--type-right-up"
                        variant="secondary"
                        size="text"
                        className="mb-sm"
                    />
                )}
                <Text
                    className={cls('font-b500-regular', styles.name)}
                    component="p">
                    {name}{' '}
                    {!!role && (
                        <span className={cls('display-xs-block')}>{role}</span>
                    )}
                </Text>
            </div>
        </div>
    );
}
