import React from 'react';

import { Row, Col, Container } from '@soluto-design/react';
import cls from '@soluto-design/styles/cls';
import GridSmallAtom, {
    GridSmallAtomProps,
} from '../../molecules/GridSmallAtom';

import SectionTheme, { SectionThemeProps } from '../../molecules/SectionTheme';

export interface TeamSectionProps extends SectionThemeProps {
    team: GridSmallAtomProps[];
}

export default function TeamSection({ team, ...props }: TeamSectionProps) {
    return (
        <SectionTheme {...props} className={cls('py-huge')}>
            <Container>
                <Row>
                    {team.map((member: GridSmallAtomProps, i: number) => {
                        return (
                            <Col key={i} col={{ md: 4, lg: 4 }}>
                                <GridSmallAtom
                                    {...member}
                                    className={cls('mb-xxl')}
                                />
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        </SectionTheme>
    );
}
