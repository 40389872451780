import React, { useCallback, MouseEvent } from 'react';
import { ButtonProps } from '@soluto-design/react';
import styles from './styles.module.scss';
import { Button } from '@soluto-design/react';

export interface CursorProps {
    onClick?: (e: React.MouseEvent<HTMLDivElement | undefined>) => void;
    component?: ButtonProps['component'];
    buttonLabel?: string;
    dataTheme?: 'black' | 'white';
}

export default function Cursor({
    onClick,
    component,
    buttonLabel = 'button',
    dataTheme,
}: CursorProps) {
    const onMouseMove = useCallback(
        ({ nativeEvent, currentTarget }: MouseEvent) => {
            const cursor: HTMLButtonElement | null =
                currentTarget.querySelector('[data-cursor]');

            if (cursor) {
                const rect: any = currentTarget.getBoundingClientRect();
                cursor.style.setProperty('--bx', `${nativeEvent.x - rect.x}px`);
                cursor.style.setProperty('--by', `${nativeEvent.y - rect.y}px`);
            }
        },
        [],
    );

    return (
        <div
            className={styles.hover}
            onClick={onClick}
            onMouseMove={onMouseMove}
            data-theme={dataTheme}>
            <Button
                data-cursor
                variant="secondary"
                size="text"
                component={component}>
                {buttonLabel}
            </Button>
        </div>
    );
}
