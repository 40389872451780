import React from 'react';
import { Title, Text } from '@soluto-design/react';
import CardTextSmall from '../CardTextSmall';
import cls from '@soluto-design/styles/cls';
import styles from './styles.module.scss';

export interface BlockServiceProps {
    title: string;
    text?: string;
    items?: { title: string }[];
}

export default function BlockService({
    title,
    text,
    items,
}: BlockServiceProps) {
    return (
        <>
            {!!title && (
                <Title
                    isHtml
                    component="h1"
                    className={cls(
                        'font-b100-regular text-primary pr-lg',
                        styles.title,
                    )}>
                    {title}
                </Title>
            )}
            {!!text && (
                <Text
                    isHtml
                    component="div"
                    className={cls(
                        'font-c200-regular text-secondary pt-xxl pr-lg',
                        styles.text,
                    )}>
                    {text}
                </Text>
            )}
            {items && items?.length > 0 && (
                <div
                    className={cls(
                        'pt-lg pb-xxl display-xs-flex flex-xs-column',
                        styles.itemsWrapper,
                    )}>
                    {items.map((item, index) => (
                        <CardTextSmall
                            key={index}
                            {...item}
                            className={cls(styles.card)}
                        />
                    ))}
                </div>
            )}
        </>
    );
}
