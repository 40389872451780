import React, { useMemo } from 'react';

import { Button, Text } from '@soluto-design/react';
import cls from '@soluto-design/styles/cls';
import Link, { LinkProps } from '@soluto-design/link';

import styles from './styles.module.scss';

export interface LinkNavigationProps extends LinkProps {
    size?: 'large' | 'small';
}

export default function LinkNavigation({
    children,
    size = 'large',
    className,
    href,
    ...props
}: LinkNavigationProps) {
    const Component = useMemo(() => (href ? Link : Text), [href]);

    return (
        <Component
            {...(size === 'large' && { 'data-theme': 'white' })}
            className={cls(
                styles.root,
                styles[size],
                'no-decoration display-xs-flex justify-xs-between align-xs-center',
                size === 'large'
                    ? 'font-b400-regular py-xs pl-sm pr-xs radius-medium'
                    : 'font-b500-regular py-tiny pr-modulor',
                className,
            )}
            {...(!!href && { href })}
            {...props}>
            <span
                className={styles.text}
                dangerouslySetInnerHTML={{ __html: children }}
            />
            <Button
                variant="secondary"
                size="icon"
                component="span"
                rightIcon="arrow-corner--type-right-up"
                className={cls('ml-md', styles.button)}
            />
        </Component>
    );
}
