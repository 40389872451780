import { useCallback, useRef } from 'react';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { useIsBreakpointDown } from '@soluto-design/react';

let scrollPosition = 0;

const useDisableScroll = () => {
    const scrollRef = useRef();
    const isMobile = useIsBreakpointDown('md');

    const disableScroll = useCallback(() => {
        document.body.dataset.noScroll = 'true';

        if (isMobile) {
            scrollPosition = window.pageYOffset;
            document.body.style.overflow = 'hidden';
            document.body.style.position = 'fixed';
            document.body.style.top = `-${scrollPosition}px`;
            document.body.style.width = '100%';
            (document.body as any).dataset.disabledScroll = 'true';
        } else {
            disableBodyScroll(scrollRef.current || document.body);
        }
    }, [isMobile, scrollRef.current]);

    const enableScroll = useCallback(() => {
        delete document.body.dataset.noScroll;

        if (document.body.dataset.disabledScroll) {
            document.body.style.removeProperty('overflow');
            document.body.style.removeProperty('position');
            document.body.style.removeProperty('top');
            document.body.style.removeProperty('width');
            delete document.body.dataset.disabledScroll;
            window.scrollTo(0, scrollPosition);
        } else {
            clearAllBodyScrollLocks();
        }
    }, [isMobile]);

    return { disableScroll, enableScroll, scrollRef };
};

export default useDisableScroll;
