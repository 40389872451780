import React, { useRef, useEffect, useCallback } from 'react';

import { useVideoAsk } from '@solublestudio/soluble-design-system';

import styles from './styles.module.scss';

interface VideoAskProps {
    videoId: string;
}

function trackEvent(event: string, data?: any) {
    if (typeof window === 'undefined') {
        return;
    }

    if ((window as any)?.dataLayer) {
        (window as any).dataLayer.push({
            event,
            videoask_id: null,
            question_id: null,
            option_id: null,
            ...data,
        });
    }
}

export default function VideoAsk({ videoId }: VideoAskProps) {
    const { previousId } = useVideoAsk();
    const elementRef = useRef<HTMLDivElement>(null);
    const modalRef = useRef<HTMLElement>();

    const loadWidget = useCallback(
        (id) => {
            const VIDEOASK_EMBED_CONFIG = {
                kind: 'widget',
                url: `https://www.videoask.com/${id}`,
                options: {
                    widgetType: 'VideoThumbnailWindow',
                    modalType: 'Side',
                    backgroundColor: 'transparent',
                    position: 'bottom-right',
                    dismissible: false,
                    widgetZIndex: '300',
                    widgetTarget: `.${styles.root}`,
                },
            };

            (window as any).videoask.loadEmbed(VIDEOASK_EMBED_CONFIG, {
                onLoadModal: ({ element, ...props }: any) => {
                    element.classList.add(styles.modal);
                    modalRef.current = element;

                    trackEvent('videoask_open', { videoask_id: id });
                },
                onCloseModal: () => {
                    trackEvent('videoask_close', { videoask_id: id });
                },
                onMessage: ({ type, questionId, optionId }) => {
                    switch (type) {
                        case 'videoask_question_presented':
                            trackEvent('videoask_question_presented', {
                                question_id: questionId,
                                videoask_id: id,
                            });
                            break;
                        case 'videoask_question_submitted':
                            trackEvent('videoask_question_submitted', {
                                question_id: questionId,
                                option_id: optionId,
                                videoask_id: id,
                            });
                            break;
                    }
                },
            });
        },
        [modalRef],
    );

    const close = useCallback(() => {
        if (elementRef?.current) {
            elementRef.current.innerHTML = '';
        }

        if (modalRef?.current) {
            modalRef.current.remove();
            modalRef.current = undefined;
        }
    }, [elementRef, modalRef]);

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        function load() {
            if (!videoId) {
                close();
            }

            if (previousId?.current !== videoId && videoId) {
                close();
                loadWidget(videoId);
            }

            previousId.current = videoId ?? undefined;
        }

        if ((window as any).videoask) {
            load();
            return;
        }

        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.defer = true;
        s.src = 'https://www.videoask.com/embed/embed.js';
        const x = document.getElementsByTagName('head')[0];
        x.appendChild(s);

        s.addEventListener('load', load);

        return () => {
            s.removeEventListener('load', load);
        };
    }, [videoId, loadWidget, previousId, close]);

    return (
        <div ref={elementRef} data-screenshot-hide className={styles.root} />
    );
}
