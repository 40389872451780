import React, { createContext, useContext, useState, useCallback } from 'react';

import Toast from '../../components/atoms/Toast';

// Create a context for the toast provider
const ToastContext = createContext<any>(null);

const TOAST_DURATION = 7500;

// Create a toast provider component
export const ToastProvider: React.FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    const [toast, setToast] = useState<string | null>(null);

    // Function to show a toast message
    const showToast = useCallback(
        (message: string, time: number = TOAST_DURATION) => {
            setToast(message);

            setTimeout(() => {
                setToast(null);
            }, time);
        },
        [],
    );

    // Function to hide the toast message
    const hideToast = useCallback(() => {
        setToast(null);
    }, []);

    return (
        <ToastContext.Provider value={{ showToast, hideToast }}>
            {children}
            <Toast visible={!!toast} onClose={hideToast}>
                {toast}
            </Toast>
        </ToastContext.Provider>
    );
};

// Custom hook to access the toast provider
export const useToast = () => {
    const toastContext = useContext(ToastContext);

    if (!toastContext) {
        throw new Error('useToast must be used within a ToastProvider');
    }

    return toastContext;
};
