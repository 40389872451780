import React, { HTMLAttributes } from 'react';

import { Container, Title } from '@soluto-design/react';
import cls from '@soluto-design/styles/cls';

import LinkNavigation, {
    LinkNavigationProps,
} from '../../molecules/LinkNavigation';
import styles from './styles.module.scss';

export interface LinkNavigationSectionProps
    extends HTMLAttributes<HTMLElement> {
    title?: string;
    links: LinkNavigationProps[];
}

export default function LinkNavigationSection({
    links,
    className,
    title,
}: LinkNavigationSectionProps) {
    return (
        <section className={cls('py-extra', className)}>
            <Container>
                {!!title && (
                    <Title
                        component="h4"
                        className={cls(
                            styles.title,
                            'font-b200-regular mb-xl',
                        )}>
                        {title}
                    </Title>
                )}
                {links?.map((link, i) => (
                    <LinkNavigation key={`link-${i}`} {...link} />
                ))}
            </Container>
        </section>
    );
}
