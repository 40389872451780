import React from 'react';

import { Col, Container, Row } from '@soluto-design/react';

import Resume, { ResumeProps } from '../../molecules/Resume';
import ArticleEnrichedText, {
    ArticleEnrichedTextRowProps,
} from '../../organisms/ArticleEnrichedText';
import CardMediaCarouselSection from '../../organisms/CardMediaCarouselSection';
import HeroPost from '../../organisms/HeroPost';
import cls from '@soluto-design/styles/cls';

export interface PostTemplateProps {
    resumeBlocks?: ResumeProps['blocks'];
    bottomBlocks?: ResumeProps['blocks'];
    title: string;
    rows: ArticleEnrichedTextRowProps[];
    featuredImage: any;
    relatedTags: any;
    relatedPosts?: any[];
    relatedPostsTitle?: string;
    collapseLabel?: string;
}

export default function PostTemplate({
    resumeBlocks,
    title,
    rows,
    relatedTags,
    relatedPosts,
    featuredImage,
    bottomBlocks,
    relatedPostsTitle,
    collapseLabel,
}: PostTemplateProps) {
    return (
        <article data-article={!!featuredImage}>
            {featuredImage && (
                <HeroPost
                    image={featuredImage}
                    className="mb-xxxl"
                    rounded="bottom"
                />
            )}
            <Container className={cls(!featuredImage && 'pt-xxxl')}>
                {!!rows?.length && (
                    <ArticleEnrichedText
                        leftSideBarBlocks={resumeBlocks}
                        relatedTags={relatedTags}
                        title={title}
                        rows={rows}
                        collapseLabel={collapseLabel}
                    />
                )}
                <Row className="mt-huge mb-huge">
                    <Col
                        col={{ md: 6, lg: 6 }}
                        offset={{ md: 1, lg: 3 }}
                        order={{
                            xs: 3,
                        }}>
                        {!!bottomBlocks?.length && (
                            <Resume blocks={bottomBlocks} />
                        )}
                    </Col>
                </Row>
            </Container>

            {relatedPosts && relatedPosts?.length > 0 && (
                <CardMediaCarouselSection
                    title={relatedPostsTitle}
                    cards={relatedPosts}
                    variant="related-posts"
                />
            )}
        </article>
    );
}
