import React, {
    useState,
    useRef,
    useEffect,
    useCallback,
    forwardRef,
    useImperativeHandle,
    useMemo,
} from 'react';
import { ReactSketchCanvas } from '../../../../libs/react-sketch-canvas';

import cls from '@soluto-design/styles/cls';
import { useIsBreakpointDown } from '@soluto-design/react';

import Cursor from './Cursor';

import styles from './styles.module.scss';

export interface Color {
    name: string;
    hex: string;
}

export interface BlackboardProps {
    color: Color;
    thickness: number;
    isDrawing: boolean;
    isChoosing: boolean;
    isSharing: boolean;
}

export interface BlackboardType {
    reset: any;
    undo: any;
    exportSvg: any;
}

const Blackboard = forwardRef<BlackboardType, BlackboardProps>(
    ({ color, thickness, isDrawing, isChoosing, isSharing }, ref) => {
        const canvas = useRef<any>(null);

        const [visibleCurrentThickness, setVisibleCurrentThickness] =
            useState(0);
        const isMobile = useIsBreakpointDown('md');
        const [currentDrawing, setCurrentDrawing] = useState<boolean>(false);
        const [documentWidth, setDocumentWidth] = useState<number>(0);
        const [documentHeight, setDocumentHeight] = useState<number>(0);

        const isDrawingOrSharing = useMemo(
            () => isDrawing || isSharing,
            [isDrawing, isSharing],
        );

        const setDocumentSize = useCallback(() => {
            if (typeof window === 'undefined') {
                return;
            }

            const height = window.document.body.offsetHeight
                ? window.document.body.offsetHeight
                : window.innerHeight;
            const width = window.document.body.offsetHeight
                ? window.document.body.offsetWidth
                : window.innerWidth;

            setDocumentHeight(height);
            setDocumentWidth(width);
        }, []);

        const undo = useCallback(() => {
            if (typeof window === 'undefined') {
                return;
            }

            if (canvas.current) {
                (canvas.current as any).undo();
            }
        }, [canvas]);

        const reset = useCallback(() => {
            if (typeof window === 'undefined') {
                return;
            }

            setDocumentSize();
            if (canvas.current) {
                (canvas.current as any).clearCanvas();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [setDocumentSize, canvas.current]);

        useEffect(() => {
            if (!thickness || typeof window === 'undefined') {
                return;
            }

            const proportionalThickness = (window.innerWidth / 700) * thickness;

            setVisibleCurrentThickness(
                thickness > proportionalThickness
                    ? thickness
                    : proportionalThickness,
            );
        }, [thickness, setVisibleCurrentThickness]);

        useEffect(() => {
            if (typeof window === 'undefined' || !isDrawingOrSharing) {
                return;
            }

            setDocumentSize();
        }, [setDocumentSize, isDrawingOrSharing, canvas]);

        useEffect(() => {
            if (typeof window === 'undefined') {
                return;
            }

            if (isMobile) {
                window.addEventListener('orientationchange', reset);
            } else {
                window.addEventListener('resize', reset);
            }

            window.addEventListener('@bb/reset', reset);

            return () => {
                window.removeEventListener('orientationchange', reset);
                window.removeEventListener('resize', reset);
                window.removeEventListener('@bb/reset', reset);
            };
        }, [reset, canvas, isMobile]);

        useEffect(() => {
            if (typeof window === 'undefined') {
                return;
            }

            window.document.body.dataset.isDrawing = isDrawingOrSharing
                ? 'true'
                : 'false';
        }, [isDrawingOrSharing]);

        useEffect(() => {
            if (typeof window === 'undefined') {
                return;
            }

            window.document.body.dataset.drawing = currentDrawing
                ? 'true'
                : 'false';
        }, [currentDrawing]);

        const onPointerDown = useCallback(() => {
            setCurrentDrawing(true);
        }, [setCurrentDrawing]);

        const onPointerUp = useCallback(() => {
            setCurrentDrawing(false);
        }, [setCurrentDrawing]);

        useImperativeHandle(
            ref,
            () => ({
                reset,
                undo,
                exportSvg: (canvas?.current as any)?.exportSvg,
            }),
            [canvas.current, reset, undo],
        );

        return (
            <>
                {!!documentWidth && !!documentHeight && (
                    <ReactSketchCanvas
                        ref={canvas}
                        className={cls(
                            styles.canvas,
                            !isDrawingOrSharing && 'display-xs-none',
                        )}
                        width={`${documentWidth}px`}
                        height={`${documentHeight}px`}
                        strokeWidth={visibleCurrentThickness}
                        strokeColor={color?.hex}
                        canvasColor="transparent"
                        onPointerDown={onPointerDown}
                        onPointerUp={onPointerUp}
                    />
                )}
                {!isMobile && isDrawing && (
                    <Cursor
                        color={color}
                        thickness={visibleCurrentThickness}
                        showThickness={isChoosing}
                    />
                )}
            </>
        );
    },
);

export default Blackboard;
