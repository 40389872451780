import React, { useMemo } from 'react';
import EnrichedText, {
    EnrichedTextRowProps,
} from '../../organisms/EnrichedText';

interface NotionDataType {
    title: string;
    services: string[];
    year: string;
    rows: EnrichedTextRowProps[];
    client?: string;
}

export interface CaseStudyTemplateProps {
    json: NotionDataType;
    year?: string;
    services?: string;
}

export default function CaseStudyTemplate({
    json,
    year = 'Año',
    services = 'Servicios',
}: CaseStudyTemplateProps) {
    const tags = useMemo(() => {
        const tags = {};

        if (json?.services?.length) {
            tags[services] = json.services;
        }

        if (json?.year) {
            tags[year] = [json.year];
        }

        return tags;
    }, [services, year, json]);

    return (
        <EnrichedText
            title={json?.client}
            subtitle={json?.title}
            tags={tags}
            rows={json.rows}
        />
    );
}
