import React from 'react';
import cls from '@soluto-design/styles/cls';

import {
    Title as TitleDS,
    TitleProps as TitlePropsDS,
    Text,
} from '@soluto-design/react';

import styles from './styles.module.scss';

export interface TitleProps {
    tag?: TitlePropsDS['component'];
    uppercase?: boolean;
    className?: string;
    size?: 'large' | 'medium' | 'small';
    title: string;
    subtitle?: string;
}

export default function Title({
    tag = 'h1',
    uppercase = true,
    className,
    size = 'large',
    subtitle,
    title,
}: TitleProps) {
    return (
        <>
            <TitleDS
                isHtml
                component={tag}
                className={cls(
                    'display-xs-block',
                    uppercase && 'text-uppercase',
                    className,
                    styles.title,
                    size && size === 'large'
                        ? 'font-h200-regular'
                        : size === 'medium'
                        ? 'font-h300'
                        : size === 'small'
                        ? 'font-b400-regular'
                        : null,
                )}>
                {title}
            </TitleDS>
            {subtitle && (
                <Text
                    className={cls(
                        'font-b400-regular',
                        size === 'large' ? 'pt-lg' : 'pt-md',
                    )}
                    component="p">
                    {subtitle}
                </Text>
            )}
        </>
    );
}
