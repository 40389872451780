import React, { useState, useEffect, useCallback } from 'react';
import { Canvas as CanvasSketch } from '../../../../libs/react-sketch-canvas';

import { useIsBreakpointDown } from '@soluto-design/react';
import cls from '@soluto-design/styles/cls';

import styles from './styles.module.scss';

import stroke1 from './stroke1.json';
import stroke2 from './stroke2.json';
import stroke3 from './stroke3.json';
import stroke4 from './stroke4.json';
import stroke5 from './stroke5.json';
import stroke6 from './stroke6.json';
import stroke7 from './stroke7.json';
import stroke8 from './stroke8.json';
import stroke1Mobile from './stroke1-mobile.json';
import stroke2Mobile from './stroke2-mobile.json';
import stroke3Mobile from './stroke3-mobile.json';
import stroke4Mobile from './stroke4-mobile.json';
import stroke5Mobile from './stroke5-mobile.json';
import stroke6Mobile from './stroke6-mobile.json';
import stroke7Mobile from './stroke7-mobile.json';
import stroke8Mobile from './stroke8-mobile.json';

const strokes = [
    stroke1,
    stroke2,
    stroke3,
    stroke4,
    stroke5,
    stroke6,
    stroke7,
    stroke8,
];

const strokesMobile = [
    stroke1Mobile,
    stroke2Mobile,
    stroke3Mobile,
    stroke4Mobile,
    stroke5Mobile,
    stroke6Mobile,
    stroke7Mobile,
    stroke8Mobile,
];

const colors = ['#FFD337', '#19E275', '#3CDCFF', '#FF2525', '#B455FF'];

const widths = [50, 55, 60, 65];

const getRandomInt = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

export default function Canvas({ mounted }: { mounted: boolean }) {
    const [drawingPaths, setDrawingPaths] = useState([]);
    const [hide, setHide] = useState(false);
    const isMobile = useIsBreakpointDown('md');

    const getCustomPaths = useCallback(
        (totalPaths: any = []) => {
            setHide(false);

            let pathsNumber = 0;
            const totalPathsMaxIndex = totalPaths.map((path: any) => {
                let thisNumber = path.paths.length;
                pathsNumber += path.paths.length;
                return thisNumber;
            });

            const FPS = 1500 / pathsNumber;

            let currentIndex = 0;
            let currentPathIndex = 1;
            let interval = null;

            const strokeColor = colors[getRandomInt(0, 4)];
            const strokeWidth = widths[getRandomInt(0, 3)];

            totalPaths = totalPaths.map((path) => ({
                ...path,
                strokeColor,
                strokeWidth,
            }));

            interval = window.setInterval(() => {
                setDrawingPaths([
                    ...totalPaths.slice(0, currentIndex),
                    {
                        ...totalPaths[currentIndex],
                        paths: totalPaths[currentIndex].paths.slice(
                            0,
                            currentPathIndex,
                        ),
                    },
                ]);

                if (currentPathIndex === totalPathsMaxIndex[currentIndex]) {
                    if (currentIndex === totalPathsMaxIndex.length - 1) {
                        window.clearInterval(interval);
                        interval = null;
                    }

                    currentPathIndex = 1;
                    currentIndex++;
                } else {
                    currentPathIndex++;
                }
            }, FPS);

            setTimeout(() => {
                setHide(true);
            }, 1300);
        },
        [setDrawingPaths, setHide],
    );

    useEffect(() => {
        if (!mounted) {
            return;
        }

        getCustomPaths(
            isMobile
                ? strokesMobile[getRandomInt(0, 7)]
                : strokes[getRandomInt(0, 7)],
        );
    }, [mounted, getCustomPaths, isMobile]);

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        window.document.body.dataset.transition = mounted
            ? 'inprogress'
            : 'done';
    }, [mounted]);

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        const onResize = () => {
            window.document.documentElement.style.setProperty(
                '--transition-transform',
                `translate(-50%, -50%) scale(${
                    window.innerWidth < 992
                        ? window.innerHeight / 630
                        : window.innerWidth / 900
                })`,
            );
        };

        onResize();

        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    return (
        <CanvasSketch
            paths={drawingPaths}
            width="100%"
            height="100%"
            strokeWidth={30}
            strokeColor="#ffffff"
            canvasColor="transparent"
            withTimestamp
            exportWithBackgroundImage={true}
            preserveBackgroundImageAspectRatio="none"
            allowOnlyPointerType="all"
            onPointerUp={() => {}}
            onPointerDown={() => {}}
            className={cls(styles['canvas'], hide && styles.hide)}
        />
    );
}
